import React, { useEffect } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import FirstScreen from "./views/SignUp/FirstScreen";
import SignUpNew from "./views/SignUp/SignUpNew";
import Jitsi from "./views/Jitsi/Jitsi";
import Sidebar from "./components/sidebar/Sidebar";
import NewCall from "./views/NewCall/NewCall";
import HomeScreen from "./views/home/HomeScreen";
import Login from "./views/Login/Login";
import Patients from "./views/Patients/Patients";
import { AuthProvider, useAuth } from "./middleware/authContext";
import NotFound from "./views/NotFoundPage";
import { toast, ToastContainer } from "react-toastify";
import "./App.css";
import AddProfile from "./views/AddProfile";
import { SearchProvider } from "./middleware/searchContext";
import "react-quill/dist/quill.snow.css";
import PatientRegistrationForm from "./views/Patients/addPatient";
import { getToken, messaging, onMessage, onMessageListener } from "./FirebaseAuth";
import JitsiMeeting from "./views/Jitsi/JitsiMeeting";

const AppContent = () => {
  const ProtectedRoute = ({ children }) => {
    const email = localStorage.getItem("email");

    if (!email) {
      return <Navigate to="/login" replace />;
    }

    return children ? children : <Outlet />;
  };

  const location = useLocation();
  const knownPaths = [
    "/",
    "/addProfile",
    "/chatlist",
    "/chat",
    "/query",
    "/calllogs",
    "/logPreview",
    "/logPreview2",
    "/newcall",
    "/patients",
    "/oppChat",
    "/profile",
    "/videocall1"
  ];

  const hideSidebar = !knownPaths.includes(location.pathname);

  const handleMeetingEnd = () => {
    console.log('Meeting has ended.');
    // Handle meeting end logic here
};

  return (
    <div style={{ display: "flex" }}>
      {!hideSidebar && <Sidebar />}
      <main
        style={{
          flexGrow: 1,
          padding:
            location.pathname === "/profile" ||
            location.pathname === "/videocall"
              ? 0
              : 16,
        }}
      >
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/newcall" element={<NewCall />} />
            <Route path="/profile" element={<AddProfile />} />
            <Route path="/patients" element={<Patients />} />
            <Route path="/videocall" element={<JitsiMeeting roomName="HiveMeetingRoom" onMeetingEnd={handleMeetingEnd}/>} />
            <Route path="/videocall1" element={<Jitsi roomName="HiveMeetingRoom" onMeetingEnd={handleMeetingEnd}/>} />
            {/* <Route path="/profile" element={<Profile />} /> */}
            {/* <Route path="/chatlist" element={<ChatList />} />
            <Route path="/chat" element={<ChatScreen />} /> 
            <Route path="/query" element={<QueryScreen />} /> */}
            {/* <Route path="/calllogs" element={<CallLogScreen />} /> */}
            {/* <Route path="/logPreview" element={<CallLogPreview />} /> */}
            {/* <Route path="/logPreview2" element={<CallLogP2 />} />  */}
            {/* <Route path="/oppChat" element={<ChatRoomOpp />} /> */}
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
    </div>
  );
};

const showNotification = (title, body) => {
  const options = {
    body: body,
    icon: 'path/to/icon.png', // Optional: specify an icon
  };

  new Notification(title, options);
};

function App() {
  useEffect(() => {
    const requestNotificationPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        console.log("Notification permission granted.");
        getFCMToken();
      } else {
        console.error("Unable to get permission to notify.");
      }
    };

    const getFCMToken = async () => {
      try {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY, // Your VAPID key
        });
        console.log("FCM Token:", token);
        // Send the token to your server or save it
      } catch (error) {
        console.error("Error getting FCM token:", error);
      }
    };

    requestNotificationPermission();
  }, []);


  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Received foreground message:', payload);
      showNotification(payload.notification.title, payload.notification.body);
      // Handle the message as needed
    });

    return () => {
      unsubscribe();
    };
  }, []);
  
  onMessageListener().then(payload => { 
    console.log({title: payload.notification.title, body: payload.notification.body})
    console.log(payload);
  }).catch(err => console.log('failed: ', err));

   
  return (
    <div className="App">
      <ToastContainer />
      <AuthProvider>
        <SearchProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<FirstScreen />} />
            {/* <Route
          path="/verify-email"
          element={<CheckEmailVerification onVerified={handleVerificationSuccess} />}
        /> */}
            {/* <Route path="/signup" element={<Signup />} /> */}
            <Route path="/signupform2" element={<SignUpNew />} />
            {/* <Route path="/*" element={<AppContent />} /> */}
            <Route path="/*" element={<AppContent />} />
          </Routes>
        </SearchProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
