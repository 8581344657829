import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import DocImage from "../../assets/loginDoc.jpeg";
import logo from "../../assets/logo.png";
import theme from "../../layout/theme";
import { useForm } from "react-hook-form";
import CommonBotton from "../../components/CommonBotton";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuth } from "../../middleware/authContext";
import { toast } from "react-toastify";
import { auth } from "../../FirebaseAuth";
import { signInWithEmailAndPassword } from "firebase/auth";

const Item = styled(Paper)(({ theme }) => ({}));

const staticEmail = "user2@gmail.com";
const staticPassword = "user2@221";

const Login = () => {
  const navigate = useNavigate();

  const SignupNavigate = () => {
    navigate("/signup");
  };

  const { login } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = async (data) => {
    // console.log(data);
    // Validate against static credentials
    await signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log("User signed in:", user);
        navigate("/");
        login(data.email);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage);
        console.error("Error signing in:", errorCode, errorMessage);
        // ...
      });

    if (data.email === staticEmail && data.password === staticPassword) {
      login(data.email);
      localStorage.setItem("email", data.email);
      localStorage.setItem("screenTab", 0);
      localStorage.setItem("screenNumber", 0);
      // navigate("/");
    } else {
      // toast.error("Invalid email or password");
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div>
      <Box
        sx={{ flexGrow: 1 }}
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container spacing={2}>
          <Grid
            item
            sm={6}
            md={7}
            lg={8}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Item
              sx={{
                background: `url(${DocImage}) no-repeat center center fixed`,
                backgroundSize: "cover",
                height: "100vh",
                width: "100%",
                display: "flex !important",
                justifyContent: "start !important",
                alignItems: "end !important",
                overflow: "hidden",
                borderRadius: "0px",
                position: "relative",

                "::after": {
                  content: "''",
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: "0%",
                  width: "100%",
                  background:
                    "linear-gradient(to top, rgba(0, 0, 0, 0.818), rgba(0, 0, 0, 0))",
                  mixBlendMode: "multiply",
                  borderRadius: "0px", // Adjust border radius to match container
                  boxShadow: "0px 0 0px rgba(0, 0, 0, 0.3)", // Adjust shadow as needed
                  overflow: "hidden",
                },
              }}
            >
              <Stack sx={{ overflow: "hidden", zIndex: 2, width: "80%" }}>
                <Typography
                  variant="p"
                  px={5}
                  fontSize={48}
                  sx={{
                    color: "white",
                    textAlign: "start",
                    fontFamily: theme.typography.fontFamily,
                  }}
                >
                  Empowering healthcare collabroration
                </Typography>

                <Typography
                  variant="p"
                  fontSize={20}
                  pt={1}
                  pb={5}
                  px={5}
                  sx={{
                    color: "white",
                    textAlign: "start",
                    fontFamily: theme.typography.fontFamily,
                  }}
                >
                  Seamlessly connect general practitioners with specialized care
                  through our secure medical SaaS platform.
                </Typography>
              </Stack>
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            lg={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                width: "75%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  padding: "12px",
                  border: `solid 1px ${theme.palette.primary.main}`,
                  borderRadius: "100%",
                  boxShadow: `${theme.palette.primary.main} 0px 0px 0px 0.1px inset , rgb(255, 255, 255) 0px 0px 0px 31px , ${theme.palette.primary.main} 0px 0px 0px 31px, rgb(255, 255, 255) 0px 0px 0px 61px , ${theme.palette.primary.main} 0px 0px 0px 61px , rgb(255, 255, 255) 0px 0px 0px 91px , ${theme.palette.primary.main} 0px 0px 0px 91px , rgb(255, 255, 255) 0px 0px 0px 121px , ${theme.palette.primary.main} 0px 0px 0px 121px , rgb(255, 255, 255) 0px 0px 0px 150px , ${theme.palette.primary.main} 0px 0px 0px 150px`,
                }}
              >
                <img src={logo} alt="" width={50} />
              </Box>
              <Typography variant="h5" my={3} sx={{  fontFamily: theme.typography.fontFamily,}}>
                Welcome Back
              </Typography>

              <form
                className="w-100"
                action=""
                id="signupform"
                onSubmit={handleSubmit(submit)}
                noValidate
              >
                <Box>
                  <Stack
                    spacing={2}
                    sx={{
                      width: "100%",
                    }}
                    // width={{
                    //   xs: 100,
                    //   sm: 300,
                    //   md: 350,
                    // }}
                  >
                    <div className="text-start">
                      <InputLabel
                        shrink
                        htmlFor="email"
                        sx={{
                          fontWeight: "501",
                        }}
                      >
                        Email / User ID
                      </InputLabel>
                      <TextField
                        id="email"
                        size="small"
                        fullWidth
                        placeholder="Enter your email or User ID"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            // value: /^[a-zA-Z0-9._%+-]+@(gmail|yahoo)\.com$/i,
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                            message: "Please enter a valid  email address",
                          },
                        })}
                        sx={{
                          backgroundColor: "white",
                        }}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        FormHelperTextProps={{ style: { margin: 0 } }}
                      />
                    </div>

                    <div className="text-start">
                      <InputLabel
                        shrink
                        htmlFor="pass"
                        sx={{
                          fontWeight: "501",
                        }}
                      >
                        Password
                      </InputLabel>

                      <TextField
                        size="small"
                        fullWidth
                        id="pass"
                        placeholder="Enter password"
                        type={showPassword ? "text" : "password"}
                        // onChange={(e) => handleChange(e.target.value)}
                        {...register("password", {
                          required: "Password is required",
                        })}
                        sx={{
                          backgroundColor: "white",
                        }}
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        FormHelperTextProps={{ style: { margin: 0 } }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handlePasswordVisibility}
                                edge="end"
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    {/* <Typography
                      sx={{
                        fontSize: "12px",
                        textAlign: "start",
                        //   height: "300px",
                      }}
                    >
                      Forget Password ?{" "}
                      <Typography
                        variant="span"
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: "600",
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        Click Here
                      </Typography>
                    </Typography> */}
                    <CommonBotton
                      text="Continue"
                      type="submit"
                      form="signupform"
                    />
                  </Stack>
                </Box>
              </form>
              <Typography
                sx={{
                  fontSize: "12px",
                  margin: "20px auto !important",
                  fontFamily: theme.typography.fontFamily,
                  //   height: "300px",
                }}
              >
                Not registered yet ?{" "}
                <Typography
                  variant="span"
                  sx={{
                    color: theme.palette.primary.main,
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: "600",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => SignupNavigate()}
                >
                  Create an account
                </Typography>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Login;
