import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextFieldGmail from "../components/TextFieldGmail";
import DragAndDrop from "../components/DragAndDrop";
import ChatList from "./chat/ChatList/ChatList";
import {
  Article,
  CalendarToday,
  Call,
  CallEnd,
  CallMade,
  Close,
  Delete,
  Done,
  ExpandMore,
  Lock,
  Portrait,
  PriorityHigh,
  SendRounded,
} from "@mui/icons-material";
import CommonBotton from "../components/CommonBotton";
import Modal from "@mui/material/Modal";
import BlankCommonBotton from "../components/BlankCommonButton";
import { styled, useTheme } from "@mui/material/styles";
import theme, { getCustomColor } from "../layout/theme";
import emailjs from "emailjs-com";
import axios from "axios";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { collection, db, doc, onSnapshot, storage } from "../FirebaseAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../middleware/authContext";
import ComponentLoader from "../components/ComponentLoader/ComponentLoader";
import ChatWindow from "./chat/ChatWindow";
import Dp from "../assets/Steve.png";
import { BiPhoneCall } from "react-icons/bi";
import Jitsi from "./Jitsi/Jitsi";
import { arrayUnion, getDoc, setDoc, updateDoc } from "firebase/firestore";

// import JitsiMeetPopup from "./Jitsi/JitsiMeetPopup";
// import Jitsi from "./Jitsi/Jitsi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid offWhite",
  boxShadow: 24,
  borderRadius: "15px",
  px: 4,
  pt: 1,
  pb: 4,
  textAlign: "center",
};

const GridS = {
  backgroundColor: "#f9fafb",
  textAlign: "start",
  color: "gray",
};

const toolbar = {
  toolbar: [
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const chatId = (user1, user2) => {
  if (user1.toLowerCase().codePointAt(0) > user2.toLowerCase().codePointAt(0)) {
    return `${user1} & ${user2}`;
  } else {
    return `${user2} & ${user1}`;
  }
};

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  marginRight: 5,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(15px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#DC6803",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#DC6803",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#DC6803",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

const QueryScreen = () => {
  const [searchParams] = useSearchParams();
  const specialist = searchParams.get("specialist");
  const [callNow, setCallNow] = useState(false);
  //chat
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [ChatState, setChatstate] = useState();
  const [showJitsi, setshowJitsi] = useState(false);
  const [ChatUsers, setChatUsers] = useState([]);
  const [myData, setmyData] = useState();

  const handleAccept = () => {
    setshowJitsi(true);
    handleClose();
  };
  const { currentUser } = useAuth();

  useEffect(() => {
    setLoading(true);
    const getChats = () => {
      const usersCollection = collection(db, "users");

      const unsub = onSnapshot(
        usersCollection,
        (snapshot) => {
          // Map over the documents in the snapshot
          const usersList = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          if (specialist) {
            const filterSpecific = usersList.filter(
              (user) => user.speciality === specialist
            );
            setChatUsers(filterSpecific);
            setLoading(false);
          } else {
            const filteredUsers = usersList.filter(
              (user) => user.id !== currentUser.uid
            );
            setChatUsers(filteredUsers);
            setLoading(false);
          }

          // Do something with the list of users
        },
        (error) => {
          console.error("Error fetching users:", error);
        }
      );

      return () => {
        unsub();
      };
    };
    const getChats1 = () => {
      const unsub1 = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        setmyData({ ...doc.data(), id: currentUser.uid });
      });

      return () => {
        unsub1();
      };
    };
    currentUser.uid && getChats();
    currentUser.uid && getChats1();
  }, [currentUser.uid]);

  const toggleChat = (chat) => {
    // setIsChatOpen(!isChatOpen);
    setChatstate(chat);
  };
  const closeChat = () => {
    setIsChatOpen(false);
  };
  //modal
  const [open, setOpen] = React.useState(false);
  const [viewProfile, setviewProfile] = React.useState(false);
  const [cancelRequest, setcancelRequest] = React.useState(false);
  const [ConfirmcancelRequest, setConfirmcancelRequest] = React.useState(false);
  const [openCallEndModal, setOpenCallEndModal] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setviewProfile(false);
    setcancelRequest(false);
    setConfirmcancelRequest(false);
  };

  const handleOpenCallEndModal = () => {
    setOpenCallEndModal(true);
  };
  const handleClosenCallEndModal = () => {
    setOpenCallEndModal(false);
  };

  const [editorText, setEditorText] = React.useState("");
  const [files, setfiles] = React.useState([]);
  const sendEmail = () => {
    const templateParams = {
      to_email: "iamsingh727@gmail.com",
      subject: "test",
      message: "sdd",
      // HTML content for the email body
      html: editorText,
      // Attachments (if any)
      attachments: [
        {
          name: "example.txt",
          data: "base64-encoded-file-data", // Replace with the base64-encoded content of the file
        },
      ],
    };
    emailjs
      .send(
        "service_98bdmvu",
        "template_ik6q8gr",
        templateParams,
        "yTlMpMyzNy6XjnpUm",
        { isHtml: true }
      )
      .then(
        (response) => {
          console.log("Email sent successfully:", response);
        },
        (error) => {
          console.error("Email sending failed:", error);
        }
      );
  };

  const handleDataFromChild = (data) => {
    setEditorText(data);
    {
      showJitsi
        ? localStorage.setItem("ConsultSummary", data)
        : localStorage.setItem("ClinicalQuery", data);
    }
  };
  const handleDataFromChildfiles = (data) => {
    setfiles(data);
  };

  function escapeDoubleQuotes(str) {
    return str.replace(/"/g, '\\"');
  }

  function addBackslashBeforeDoubleQuotes(str) {
    return str.replace(/"/g, '\\"');
  }

  const [base64Data, setBase64Data] = useState([]);

  const handleFileInputChange = (event) => {
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Remove the data URL prefix
        setBase64Data(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  // const handleSendImage = async (img) => {
  //   const files = Array.from(img);
  //   files.forEach((file) => {
  //     const type = file.type.split("/").shift();
  //     let ab = file.name;
  //     let thumbName = ab.split(".").shift();
  //     let cc = ab.split(".").pop();
  //     const metadata = {
  //       contentType: "application/pdf", // Set the content type of the file
  //     };
  //     const storageRef = ref(storage, ab);
  //     const uploadTask = uploadBytesResumable(storageRef, file);
  //     uploadTask.on(
  //       "state_changed",
  //       (snapshot) => {
  //         const progress = Math.round(
  //           (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //         );

  //         console.log("Upload is " + progress + "% done");
  //       },
  //       (error) => {
  //         console.log("Something went wrong!" + error.code);
  //       },
  //       () => {
  //         getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
  //           base64Data.push(downloadURL)

  //         });
  //       }
  //     );
  //   });
  // };

  //   const mail = () => {
  //     const formData = new FormData();
  //     formData.append('attachment', files[0]);
  //     const body = {
  //       to: "aakash@appzroot.com",
  //       subject: "Test Email",
  //       text: `<div>${editorText}</div>`,
  //       filepath: ['https://firebasestorage.googleapis.com/v0/b/hivemed-d4551.appspot.com/o/1.jpeg?alt=media&token=f9f89836-9ae8-4520-9ecb-506bf927a1f7' ,'https://firebasestorage.googleapis.com/v0/b/hivemed-d4551.appspot.com/o/pdfs%2Finvoice.pdf?alt=media&token=867ade9d-c5d8-4236-8f74-45e9472db375']
  //     };
  //     console.log(body, "body");
  //     axios.post("http://localhost:4000/send-email", body).then((response) => {});
  //   };

  const [disabledBtn, setDisabledBtn] = useState(false);
  const handleSendImage = async (img) => {
    if (!ChatState) {
      toast.error("Please connect with doctor first");
      return "";
    }
    const files = Array.from(img);
    const downloadURLs = [];
    setDisabledBtn(true);
    for (const file of files) {
      const type = file.type.split("/").shift();
      const ab = file.name;
      const thumbName = ab.split(".").shift();
      const cc = ab.split(".").pop();
      const metadata = {
        contentType: "application/pdf", // Set the content type of the file
      };
      const storageRef = ref(storage, ab);
      const uploadTask = uploadBytesResumable(storageRef, file);

      try {
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              // console.log("Upload is " + progress + "% done");
            },
            (error) => {
              console.log("Something went wrong!" + error.code);
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then((downloadURL) => {
                  if (type === "image" && ChatState) {
                    handleSendMsg(ab, downloadURL, "", "");
                  } else if (type === "video" && ChatState) {
                    handleSendMsg(ab, "", downloadURL, "");
                  } else {
                    if (ChatState) {
                      handleSendMsg(ab, "", "", downloadURL, cc);
                    }
                  }
                  downloadURLs.push(downloadURL);
                  resolve();
                })
                .catch((error) => {
                  console.log("Error getting download URL:", error);
                  reject(error);
                });
            }
          );
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }

    // Once all files are uploaded and download URLs are retrieved
    // Trigger the email sending function
    setBase64Data(downloadURLs);
  };

  const handleSendMsg = async (
    fileName,
    imageUrl,
    videoUrl,
    fileUrl,
    fileType
  ) => {
    const myMsg = {
      sender_id: myData.id,
      send_by: myData.name,
      message: imageUrl
        ? imageUrl
        : videoUrl
        ? videoUrl
        : fileUrl
        ? fileUrl
        : "",
      type: imageUrl ? "img" : videoUrl ? "vid" : fileUrl ? fileType : "text",
      fileName: fileName ? fileName : "",
      created_at: Date.now(),
    };
    try {
      const res = await getDoc(
        doc(db, "chats", chatId(myData.name, ChatState?.name))
      );
      if (res.exists()) {
        try {
          await updateDoc(
            doc(db, "chats", chatId(myData.name, ChatState?.name)),
            {
              messages: arrayUnion({
                ...myMsg,
              }),
            }
          );
          setfiles([]);
          // await updateDoc(doc(db, "chats", chatDocId2), {
          //   messages: arrayUnion({
          //     ...myMsg,
          //   }),
          // });

          // await addDoc(collection(db, "chats", chatDocId1, "messages"), {
          //   myMsg,
          // });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          await setDoc(doc(db, "chats", chatId(myData.name, ChatState.name)), {
            messages: arrayUnion({
              ...myMsg,
            }),
          });
          setfiles([]);
          // if (imageUrl || videoUrl || fileUrl) {
          //   setFile(null);
          // }
        } catch (error) {
          console.log(error);
        }
      }
      setDisabledBtn(false);
    } catch (error) {
      console.log(error);
    }

    //   await updateDoc(doc(db, "chats", chatDocId), { msgInfo });
  };
  const mail = (downloadURLs) => {
    const body = {
      to: "aakash@appzroot.com",
      subject: "Test Email",
      text: `<div>${editorText}</div>`,
      filepath: base64Data,
    };
    // console.log(body, "body");
    axios.post("http://localhost:4000/send-email", body).then((response) => {
      if (response.statusCode === 200) {
        toast(response.message);
        setBase64Data([]);
      }
    });
  };

  const handleMeetingEnd = () => {
    // Redirect to a different page or show a custom message
    alert("The meeting has ended.");
    // You could also navigate to another route, e.g., using React Router
    // history.push('/another-page');
  };

  const quillData = localStorage.getItem("ClinicalQuery");
  const handleMouseEnter = () => {
    document.body.classList.remove("scroll-lock");
  };

  const handleMouseLeave = () => {
    document.body.classList.add("scroll-lock");
  };

  useEffect(() => {
    return () => {
      // Clean up to ensure the class is removed
      document.body.classList.remove("scroll-lock");
    };
  }, []);

  return (
    // <LayoutSidebar>

    <div className=" ">
      <ToastContainer />
      <Grid
        container
        spacing={1}
        component="main"
        className="upper-div"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Grid item md={5.5}>
          <Box className="mx-auto px-4">
            <Typography
              variant="h4"
              textAlign="left"
              // sx={{ color: getCustomColor("customColor1") }}
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "28px",
                textAlign: "left",
              }}
            >
              Clinical query
            </Typography>
            <Typography
              textAlign="left"
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                textAlign: "left",
              }}
              mb="10px"
            >
              {showJitsi
                ? "Summary of examination into a medical concern by referring doctor."
                : "Enter critical information required to better understand the medical concern"}
            </Typography>

            {showJitsi && (
              <Box>
                <Accordion
                  sx={{
                    // backgroundColor: "#eaeaea",
                    border: "1px solid gray",
                    borderRadius: "10px !important",
                    // margin: "10px 0",
                    width: "100%",
                    overflow: "hidden",
                  }}
                  // expanded={expand}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      backgroundColor: "#F9FAFB",
                      height: "20px",
                      minHeight: "48px !important",
                      // borderRadius: "10px !important",
                      borderTopLeftRadius: "10px !important",
                      borderTopRightRadius: "10px !important",
                      // margin: "10px 0",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#475467",
                        fontFamily: theme.typography.fontFamily,
                        fontSize: "12px",
                        fontWeight: "500",
                        lineHeight: "18px",
                        textAlign: "left",
                      }}
                    >
                      <Lock fontSize="15px" mx="5px" /> Clinical Query
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      className="Accordion_quill "
                      style={{
                        // border: "solid 1px gray",
                        borderRadius: "8px",
                        // backgroundColor: "#f5efef",
                        textAlign: "start",
                        padding: "5px",
                      }}
                    >
                      <ReactQuill
                        value={quillData}
                        readOnly={true}
                        theme={"bubble"}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            <Box py={3}>
              {showJitsi && (
                <Box>
                  <Typography
                    variant="h4"
                    textAlign="left"
                    // sx={{ color: getCustomColor("customColor1") }}
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "28px",
                      textAlign: "left",
                    }}
                  >
                    Consult summary
                  </Typography>
                  <Typography
                    textAlign="left"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      textAlign: "left",
                    }}
                    mb="10px"
                  >
                    A concise description of case discussed and advice received.
                  </Typography>
                </Box>
              )}

              <TextFieldGmail
                onData={handleDataFromChild}
                showJitsi={showJitsi}
              />

              <Typography
                textAlign="left"
                my="15px"
                sx={{ fontFamily: theme.typography.fontFamily }}
              >
                Attach files (e.g. ECG scans)
              </Typography>
              <DragAndDrop
                onDataFiles={handleDataFromChildfiles}
                sentfiles={files}
              />
              <Button
                onClick={() => handleSendImage(files)}
                disabled={disabledBtn}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Grid>

        <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />

        <Grid item md={5.5}>
          {/* chat */}

          <Box className="px-4">
            <div className="d-flex  align-items-end">
              <Typography
                variant="h4"
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "28px",
                  textAlign: "left",
                }}
              >
                {showJitsi === false ? "Specialist" : "Consultation"}
              </Typography>
              <span
                className="ms-2"
                style={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "12px",
                  color: "#002892",
                  border: "1px solid #91A9EA",
                  padding: "2px 5px",
                  borderRadius: "16px",
                  backgroundColor: "#E6EBFA",
                  fontWeight: "500",
                  lineHeight: "18px",
                  textAlign: "left",
                }}
                onClick={handleOpenCallEndModal}
              >
                {showJitsi === false
                  ? "7 available from 4 hospitals"
                  : "in Call"}
              </span>
            </div>
            <Typography
              textAlign="left"
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                textAlign: "left",
              }}
              my="10px"
            >
              {showJitsi === false
                ? "Select from the list of available doctors whom you want to connect with."
                : "Hive is connecting you to the selected doctors now"}
            </Typography>
            {/* <ChatList /> */}
            {!showJitsi && (
              <Box
                px={3}
                sx={{
                  border: "solid 1px gray",
                  borderRadius: "10px",
                  height: "500px",
                  overflow: "scroll",
                  position: "relative",
                }}
              >
                <div
                  className={
                    loading === true
                      ? "mx-auto d-flex justify-content-center align-items-center position-absolute top-50 start-50 translate-middle p-2"
                      : "mx-auto d-flex justify-content-center align-items-center"
                  }
                >
                  <List
                    sx={{
                      width: "100%",
                    }}
                  >
                    {loading === true ? (
                      <ComponentLoader />
                    ) : (
                      ChatUsers.map((chat, index) => (
                        <React.Fragment key={index}>
                          <ListItem
                            secondaryAction={
                              callNow && (
                                <Typography
                                  edge="end"
                                  sx={{
                                    color: getCustomColor("customColor2"),
                                    fontSize: "13px",
                                    fontFamily: theme.typography.fontFamily,
                                  }}
                                >
                                  <BiPhoneCall /> Calling..
                                </Typography>
                              )
                            }
                            alignItems="center"
                            sx={{
                              cursor: "pointer",
                              backgroundColor: "#f9fafb",
                              borderRadius: "10px",
                              margin: "10px 0",
                            }}
                            p={3}
                            onClick={() => {
                              toggleChat(chat);
                              handleOpen();
                            }}
                          >
                            <Avatar
                              alt={chat.name}
                              src={
                                chat?.profilePicture ? chat.profilePicture : Dp
                              }
                              sx={{ width: 50, height: 50, ml: 2 }}
                            />
                            <ListItemText
                              primary={chat.name}
                              secondary={chat.speciality}
                              sx={{ pl: 1 }}
                            />
                          </ListItem>
                        </React.Fragment>
                      ))
                    )}
                  </List>
                </div>
              </Box>
            )}
            {showJitsi && (
              <Box sx={{ borderRadius: "10px" }}>
                <Jitsi
                  name={ChatState?.name}
                  height="500px"
                  width="100%"
                  onMeetingEnd={handleMeetingEnd}
                />
              </Box>
            )}
            <Box
              my="20px"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  required
                  control={<IOSSwitch color="warning" />}
                  label="Patient is present with me"
                />
              </FormGroup>

              {callNow === false && showJitsi === false ? (
                <CommonBotton
                  text="Call Now"
                  width="20%"
                  my={1}
                  icon={<Call />}
                  onClick={() => setCallNow(!callNow)}
                />
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    // margin: "10px 0px",
                    backgroundColor: getCustomColor("customColor1"),
                    color: "#fff !important",
                    border: `1px solid  ${getCustomColor("customColor1")} `,
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: getCustomColor("customColor1"),
                      border: `1px solid ${getCustomColor("customColor1")}`,
                    },
                  }}
                  width="20%"
                  my={1}
                  startIcon={<CallEnd />}
                  onClick={() => {
                    setCallNow(false);
                    setshowJitsi(false);
                    setChatstate();
                  }}
                >
                  Cancel
                </Button>
              )}
            </Box>
          </Box>

          {/* <JitsiMeetPopup roomName="Hive Medical Meeting Room" /> */}
          {ChatState && (
            <Button onClick={() => setIsChatOpen(true)}>OpenChat</Button>
          )}
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          {cancelRequest === true &&
          !viewProfile &&
          ConfirmcancelRequest === true ? (
            <Box sx={style}>
              <p className="mb-0 text-end pt-2">
                <Close onClick={() => handleClose()} />
              </p>
              <PriorityHigh
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "30px",
                  color: "orange",
                  backgroundColor: "#edcf97",
                  border: "solid 3px orange",
                  borderRadius: "100%",
                  boxShadow:
                    "0px 0px 0px 7.5px #edcf97, #e5cfa5af 0px 0px 0px 15px",
                  margin: "10px",
                }}
              />
              <Stack>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: theme.typography.fontFamily }}
                >
                  Request cancelled
                </Typography>
                <Typography>
                  Please select an alternative option for your patient
                </Typography>

                <BlankCommonBotton
                  text="Book an appointment"
                  icon={<CalendarToday />}
                />
                <BlankCommonBotton text="Make a referral" icon={<Article />} />
                {/* <Button
                  startIcon={<CallEnd />}
                  sx={{
                    backgroundColor: "red",
                    borderColor: "red",
                    color: "white",

                    "&:hover": {
                      backgroundColor: "#ce1818",
                      borderColor: "red",
                      color: "white",
                    },
                  }}
                  onClick={handleClose}
                >
                  End request
                </Button> */}
              </Stack>
            </Box>
          ) : null}

          {!cancelRequest && !viewProfile && ConfirmcancelRequest === false ? (
            <Box sx={style}>
              <p className="mb-0 text-end pt-2">
                <Close onClick={() => handleClose()} />
              </p>
              <Box my={2}>
                <Done
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: "30px",
                    color: "green",
                    backgroundColor: "#bef3be",
                    border: "solid 3px green",
                    borderRadius: "100%",
                    boxShadow:
                      "0px 0px 0px 7.5px #bef3be, #dcfae6 0px 0px 0px 15.5px",
                    margin: "10px",
                  }}
                />
              </Box>
              <Stack>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: theme.typography.fontFamily }}
                >
                  Connect with Dr {ChatState?.name}
                </Typography>
                <Typography sx={{ fontFamily: theme.typography.fontFamily }}>
                  You are successfully connect with Specialist
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  my={2}
                >
                  <Avatar alt="text" sx={{ width: 50, height: 50, ml: 2 }} />
                  <Stack ml={2} textAlign="start">
                    <Typography
                      sx={{ fontFamily: theme.typography.fontFamily }}
                    >
                      Dr. {ChatState?.name} <Portrait />
                    </Typography>
                    <Typography
                      sx={{ fontFamily: theme.typography.fontFamily }}
                    >
                      {ChatState?.speciality}
                    </Typography>
                  </Stack>
                </Box>
                <BlankCommonBotton
                  text="view profile"
                  onClick={() => setviewProfile(true)}
                />

                <Divider />
                <Stack direction="row" gap={3} mx="auto">
                  <BlankCommonBotton
                    text="Cancel request"
                    onClick={() => {
                      setConfirmcancelRequest(true);
                      setviewProfile(false);
                      setChatstate();
                    }}
                  />
                  <Button
                    type="submit"
                    variant="outlined"
                    onClick={() => handleAccept()}
                    sx={{
                      margin: "10px 0px",
                      backgroundColor: getCustomColor("customColor2"),
                      color: "#fff",
                      border: `1px solid  ${getCustomColor("customColor2")}`,
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: getCustomColor("customColor2Hover"),
                        border: `1px solid ${getCustomColor(
                          "customColor2Hover"
                        )}`,
                      },
                    }}
                  >
                    Accept
                  </Button>
                </Stack>
              </Stack>
            </Box>
          ) : null}

          {viewProfile === true &&
          !cancelRequest &&
          ConfirmcancelRequest === false ? (
            <Box sx={style}>
              <p className="mb-0 text-end pt-2">
                <Close onClick={() => handleClose()} />
              </p>
              <Done
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "30px",
                  color: "green",
                  backgroundColor: "#bef3be",
                  border: "solid 3px green",
                  borderRadius: "100%",
                  boxShadow:
                    "0px 0px 0px 7.5px #bef3be, #dcfae6 0px 0px 0px 15.5px",
                  margin: "10px",
                }}
              />
              <Stack>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: theme.typography.fontFamily }}
                >
                  Connect with Dr {ChatState?.name}
                </Typography>
                <Typography sx={{ fontFamily: theme.typography.fontFamily }}>
                  You are successfully connect with Specialist
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  my={2}
                >
                  <Avatar alt="text" sx={{ width: 50, height: 50, ml: 2 }} />
                  <Stack ml={2} textAlign="start">
                    <Typography
                      sx={{ fontFamily: theme.typography.fontFamily }}
                    >
                      Dr. {ChatState?.name} <Portrait />
                    </Typography>
                    <Typography
                      sx={{ fontFamily: theme.typography.fontFamily }}
                    >
                      {ChatState?.speciality}
                    </Typography>
                  </Stack>
                </Box>
                <Stack>
                  <Grid
                    container
                    spacing={1}
                    my={2}
                    sx={{
                      backgroundColor: "#f9fafb",
                    }}
                  >
                    <Grid item sm={4} textAlign="start" pb="8px">
                      <Box style={GridS}>Rank</Box>
                      <Box style={GridS} my={1}>
                        Specialty
                      </Box>
                      <Box style={GridS}>Subspecialty</Box>
                    </Grid>
                    <Grid item sm={8} textAlign="start" pb="8px">
                      <Box>Senior Consultant</Box>
                      <Box my={1}>{ChatState?.speciality}</Box>
                      <Box>Intervention Cardiology</Box>
                    </Grid>
                  </Grid>
                </Stack>

                <Divider />
                <Stack direction="row" gap={3} mx="auto">
                  <BlankCommonBotton
                    text="Cancel request"
                    onClick={() => {
                      // setcancelRequest(true);
                      setConfirmcancelRequest(true);
                      setviewProfile(false);
                      setChatstate();
                    }}
                  />
                  <Button
                    type="submit"
                    variant="outlined"
                    onClick={() => handleAccept()}
                    sx={{
                      margin: "10px 0px",
                      backgroundColor: getCustomColor("customColor2"),
                      color: "#fff",
                      border: `1px solid  ${getCustomColor("customColor2")}`,
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: getCustomColor("customColor2Hover"),
                        border: `1px solid ${getCustomColor(
                          "customColor2Hover"
                        )}`,
                      },
                    }}
                  >
                    Accept
                  </Button>
                </Stack>
              </Stack>
            </Box>
          ) : null}

          {ConfirmcancelRequest === true && cancelRequest === false ? (
            <Box sx={style}>
              <p className="mb-0 text-end pt-2">
                <Close onClick={() => handleClose()} />
              </p>
              <PriorityHigh
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "30px",
                  color: "orange",
                  backgroundColor: "#edcf97",
                  border: "solid 3px orange",
                  borderRadius: "100%",
                  boxShadow:
                    "0px 0px 0px 7.5px #edcf97, #e5cfa5af 0px 0px 0px 15px",
                  margin: "10px",
                }}
              />
              <Stack my={2}>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: theme.typography.fontFamily }}
                >
                  Do you wish to cancel the consult ?
                </Typography>
              </Stack>
              <Stack direction="row" mx="auto" gap={1} alignItems="center">
                <Button
                  sx={{
                    backgroundColor: "#f6f6f667",
                    border: "solid 1px gray",
                    color: "black",
                    width: "100%",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#f6f6f667",
                      borderColor: "gray",
                      color: "black",
                    },
                  }}
                  onClick={() => {
                    setConfirmcancelRequest(false);
                    setviewProfile(false);
                  }}
                >
                  No
                </Button>
                <Button
                  sx={{
                    backgroundColor: "red",
                    borderColor: "red",
                    color: "white",
                    width: "100%",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#ce1818",
                      borderColor: "red",
                      color: "white",
                    },
                  }}
                  onClick={() => {
                    setcancelRequest(true);
                    setviewProfile(false);
                  }}
                >
                  Yes
                </Button>
              </Stack>
            </Box>
          ) : null}
        </Box>
      </Modal>
      <Modal
        open={openCallEndModal}
        onClose={handleClosenCallEndModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Box sx={style}>
            <p className="mb-0 text-end pt-2">
              <Close onClick={() => handleClosenCallEndModal()} />
            </p>
            <PriorityHigh
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontSize: "30px",
                color: "orange",
                backgroundColor: "#edcf97",
                border: "solid 3px orange",
                borderRadius: "100%",
                boxShadow:
                  "0px 0px 0px 7.5px #edcf97, #e5cfa5af 0px 0px 0px 15px",
                margin: "10px",
              }}
            />
            <Stack mt={3}>
              <Typography
                variant="h6"
                sx={{ fontFamily: theme.typography.fontFamily }}
              >
                Your call has ended
              </Typography>

              <BlankCommonBotton text="Back" />
            </Stack>
          </Box>
        </Box>
      </Modal>

      {isChatOpen && (
        <ChatWindow
          isOpen={isChatOpen}
          myData={myData}
          toggleChat={toggleChat}
          OppUser={ChatState}
          closeChat={closeChat}
        />
      )}
    </div>
    // </LayoutSidebar>
  );
};

export default QueryScreen;
