import React, { useState } from "react";
import { Box, Paper, Button, Typography } from "@mui/material";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { Carousel } from "react-bootstrap";
import image1 from "../assets/med1.jpg";
import image2 from "../assets/med2.jpg";
import image3 from "../assets/med3.jpg";

const MyCarousel = () => {
  const items = [
    {
      id: 1,
      label: "Item 1",
      description: "Description for item 1",
      color: "orange",
      image: image1,
    },
    {
      id: 2,
      label: "Item 2",
      description: "Description for item 2",
      color: "pink",
      image: image2,
    },
    {
      id: 3,
      label: "Item 3",
      description: "Description for item 3",
      color: "lavender",
      image: image3,
    },
  ];

  return (
    <div>
      <Carousel
        data-bs-theme="dark"
        indicators={true}
        pause="hover"
        style={{
          //   height: "85.5vh",
          backgroundColor: "Gray",
          borderRadius: "10px",
        }}
      >
        {items.map((slide, index) => (
          <Carousel.Item
            key={index}
            style={{
              height: "75vh",
              backgroundImage: `Url(${slide.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              width: "100%",
              borderRadius: "10px",
            }}
          >
            <Carousel.Caption
              className="position-absolute top-50 start-50 translate-middle text-start"
              style={{
                width: "70%",
              }}
            >
              <h3>{slide.label}</h3>
              <p>{slide.description}</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum
                illum quos nesciunt molestias ipsum beatae placeat ab corrupti
                ducimus voluptatum!
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default MyCarousel;
