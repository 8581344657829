import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonBotton from "../components/CommonBotton";
import { Controller, useForm } from "react-hook-form";
import DoctorsSSList from "../utils/speciality.json";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import BlankCommonBotton from "../components/BlankCommonButton";
import LayoutSidebar from "../layout/Layout";
import { db, doc, onSnapshot, setDoc, updateDoc } from "../FirebaseAuth";
import { useAuth } from "../middleware/authContext";
import dp from "../assets/Steve.png";
import img1 from "../assets/med1.jpg";
import upload from "../middleware/upload";
import theme from "../layout/theme";

const salutations = ["Mr", "Ms", "Dr", "A/Prof", "Prof"];

const AddProfile = () => {
  const [editForm, setEditForm] = useState(false);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { currentUser } = useAuth();
  const [user, setUserData] = useState();

  useEffect(() => {
    const getChats1 = () => {
      const unsub1 = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        setUserData({ ...doc.data(), id: currentUser.uid });
      });

      return () => {
        unsub1();
      };
    };
    currentUser.uid && getChats1();
  }, [currentUser.uid]);

  const submit = async (data) => {
    // console.log(data);
    // {
    //   "name": "Lata",
    //   "medicalLnumber": "212314448",
    //   "clinicName": "sss",
    //   "clinicAddress": "sss",
    //   "qualification": "ss",
    //   "gender": "Male",
    //   "phone": "918360191825",
    //   "specialty": "Pediatrics",
    //   "subspecialty": "Pediatric Cardiology",
    //   "radioGroup": "no",
    //   "criminalRecords": "yes"
    // profilePicture
    // }
    // if(data.imge){
    //   await upload()
    // }

    try {
      const docRef = doc(db, "users", currentUser.uid);
      await setDoc(
        docRef,
        {
          profilePicture: imageUrl ? imageUrl: user?.profilePicture ? user.profilePicture : "",
          name: data.name, 
          gender: data.gender,
          clinic: data.clinicName,
          clinicAddress: data.clinicAddress,
          medicalLicense: data.medicalLnumber,
          qualification: data.qualification,
          phone: data.phone,
          specialty: data.specialty,
          subspecialty: data.subspecialty,
          criminalRecords: data.criminalRecords,
          psychiatricAssessment: data.psychiatricAssessment,
          salutation :data.salutation
        },
        { merge: true }
      );
      setEditForm(false);
    } catch (error) {
      console.error("Error updating document: ", error);
    }

    // console.log(data);
  };

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  //Phone
  const [value, setValue] = useState("");

  const handleChangeMedicallicenseNumber = (event) => {
    const inputValue = event.target.value;
    // Allow only numeric input (including decimal point)
    if (/^\d*\.?\d*$/.test(inputValue) || inputValue === "") {
      setValue(inputValue);
    }
  };

  //Avatar

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleUpload = async (data) => {
    try {
      const imageUrl = await upload(data);
      setImageUrl(imageUrl);
      // console.log(imageUrl);
    } catch (error) {
      console.log(error);
    }
   
  };
  // Speciality

  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  // console.log(selectedSpecialty, "s");
  const [selectedSubspecialty, setSelectedSubspecialty] = useState("");

  const handleSpecialtyChange = (event) => {
    setSelectedSpecialty(event);
    setSelectedSubspecialty("");
  };

  const handleSubspecialtyChange = (event) => {
    setSelectedSubspecialty(event.target.value);
  };
  return (
    // <LayoutSidebar>
    <div>
      <div
        style={{
          padding: "200px 0 0 0",
          backgroundImage: `Url(${img1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <Grid
        container
        spacing={2}
        textAlign="start"
        p={{ sm: 4, xs: 2 }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="relative"
        bottom="50px"
      >
        <Grid item xs={9}>
          <Stack direction="row" alignItems="center">
            <Avatar
              alt="Remy Sharp"
              src={user?.profilePicture ? user?.profilePicture : dp}
              sx={{
                border: "solid 1px black",
                outline: "solid 5px white",
                backgroundColor: "white",
                width: {
                  md: 120,
                  sm: 100,
                  xs: 80,
                },
                height: {
                  md: 120,
                  sm: 100,
                  xs: 80,
                },
              }}
            />
            <Stack textAlign="start" mx="10px">
              <Typography variant="h5" sx={{  fontFamily: theme.typography.fontFamily,}}>Profile</Typography>
              <Typography variant="span" sx={{  fontFamily: theme.typography.fontFamily,}}>
                Update your profile and personal details.
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          {editForm === true ? (
            <Stack
              direction={{ sm: "row", xs: "column" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <CommonBotton
                text="Save"
                width="100px"
                type="submit"
                form="editProfile"
                onClick={() => {
                  document.getElementById("editProfile").requestSubmit();
                }}
              />
              <BlankCommonBotton
                text="Cancel"
                onClick={() => {
                  setEditForm(false);
                  reset();
                }}
              />
            </Stack>
          ) : (
            <CommonBotton
              text="Edit"
              width="100px"
              onClick={() => {
                setEditForm(true);
              }}
            />
          )}
        </Grid>
      </Grid>
      {editForm === true ? (
        <form
          action=""
          id="editProfile"
          onSubmit={handleSubmit(submit)}
          className="px-4 d-flex flex-column gap-3"
        >
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
          >
            <Grid item xs={3}>
              Name
            </Grid>
            <Grid item xs={9}>
              <div className="d-flex flex-column" style={{ width: "300px" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormControl variant="outlined" sx={{ minWidth: 90 }}>
                    <Select
                      defaultValue={user?.salutation}
                      sx={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        "& fieldset": {
                          borderRight: 0, // Remove right border to connect with TextField
                        },
                      }}
                      {...register("salutation", {
                        required: "Name is required",
                      })}
                      error={!!errors.name}
                    >
                      {salutations.map((sal, index) => (
                        <MenuItem key={index} value={sal}>
                          {sal}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    variant="outlined"
                    sx={{
                      flexGrow: 1,
                      "& .MuiOutlinedInput-root": {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      },
                    }}
                    defaultValue={user?.name}
                    {...register("name", {
                      required: "Name is required",
                    })}
                    error={!!errors.name}
                  />
                </Box>
                <span className=" text-danger" style={{ fontSize: "13px" , fontFamily: theme.typography.fontFamily,}}>
                  {!!errors.name ? errors.name?.message : ""}
                </span>
              </div>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
          >
            <Grid item xs={3}>
              Gender
            </Grid>
            <Grid item xs={9} textAlign="start">
              <FormControl sx={{ width: "250px" }}>
                <Controller
                  name="gender"
                  control={control}  
                  rules={{ required: "Gender is required" }}
                  defaultValue={user?.gender}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select" 
                      error={!!errors.gender}
                      helperText={errors.gender?.message}  
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  )}
                />
                <span className=" text-danger" style={{ fontSize: "13px" ,fontFamily: theme.typography.fontFamily,}}>
                  {!!errors.gender ? errors.gender?.message : ""}
                </span>
              </FormControl>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
          >
            <Grid item xs={3}>
              Phone Number
            </Grid>
            <Grid item xs={9} textAlign="start">
              <div>
                <Controller
                  name="phone"
                  control={control}
                  rules={{ required: "Phone number is required" }}
                  defaultValue={user?.phone}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      defaultValue={user?.phone}
                      style={{
                        width: "fit-content",
                      }}
                      className={
                        !!errors.phone
                          ? "border border-danger rounded-2"
                          : " rounded-3"
                      }
                      country={"us"}
                      enableSearch
                      // onBlur={onBlur}
                      inputProps={{
                        autoFocus: false
                      }}
                    />
                  )}
                />
                <span className=" text-danger" style={{ fontSize: "13px" ,fontFamily: theme.typography.fontFamily, }}>
                  {!!errors.phone ? errors.phone?.message : ""}
                </span>
              </div>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
          >
            <Grid item xs={3}>
              Medical License Number
            </Grid>
            <Grid item xs={9} textAlign="start">
              {" "}
              <div>
                <TextField
                  variant="outlined"
                  margin="dense" 
                  defaultValue={user?.medicalLicense}
                  type="text" // Set the type to 'text' to allow decimal input
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*", // Pattern to show numeric keyboard on mobile devices
                  }}
                  {...register("medicalLnumber", {
                    required: "Medical License Number is required",
                    onChange: handleChangeMedicallicenseNumber,
                  })}
                  error={!!errors.medicalLnumber}
                  helperText={errors.medicalLnumber?.message}
                />
              </div>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
          >
            <Grid item xs={3}>
              <span className="text-start">
                Avatar <br></br>This will be displayed on your profile.
              </span>
            </Grid>

            <Grid item xs={7} textAlign="start">
              <Box
                // width="700px"
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Input
                  type="file"
                  accept="image/*"
                  id="image-input"
                  style={{ display: "none" }}
                  onChange={(e) => setImage(e.target.files[0])}
                />
                <label htmlFor="image-input">
                  <IconButton color="primary" component="span">
                    <Avatar
                      alt=""
                      src={image ? URL.createObjectURL(image) : user?.profilePicture ? user.profilePicture: ""}
                      sx={{ width: 56, height: 56 }}
                    />
                  </IconButton>
                </label>
                <Stack direction="row" gap={2}>
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setImage(null)}
                  >
                    Delete
                  </span>
                  <span
                    style={{ color: "#e98f1a", cursor: "pointer" }}
                    onClick={() => handleUpload(image)}
                  >
                    Upload
                  </span>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
          >
            <Grid item xs={3}>
              Clinic Details
            </Grid>
            <Grid item xs={9} textAlign="start">
              <Stack direction="column" width={300}>
                <TextField
                  {...register("clinicName", {
                    required: "Clinic Name is required",
                  })}
                  defaultValue={user?.clinic}
                  error={!!errors.clinicName}
                  helperText={errors.clinicName?.message}
                ></TextField>

                <TextField
                  variant="outlined"
                  margin="dense"
                  multiline
                  defaultValue={user?.clinicAddress}
                  rows={4} // Increase the number of rows to increase the height
                  fullWidth
                  style={{ marginBottom: "16px" }} // Adjust margin as needed
                  {...register("clinicAddress", {
                    required: "Clinic Address is required",
                  })}
                  error={!!errors.clinicAddress}
                  helperText={errors.clinicAddress?.message}
                />
              </Stack>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
          >
            <Grid item xs={3}>
              Qualification
            </Grid>
            <Grid item xs={9} textAlign="start">
              <Stack direction="column" width={300}>
                <TextField
                  {...register("qualification", {
                    required: "Qualification is required",
                  })}
                  defaultValue={user?.qualification}
                  error={!!errors.qualification}
                  helperText={errors.qualification?.message}
                ></TextField>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
            mb={5}
          >
            <Grid item xs={3}>
              Specialty
            </Grid>
            <Grid item xs={9}>
              <Stack direction="row" width={400} gap={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="specialty-label">Specialty</InputLabel>
                  <Controller
                    name="specialty"
                    control={control}
                    defaultValue={user?.specialty}
                    rules={{ required: "Specialty is required" }}
                    onChange={handleSpecialtyChange}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="specialty-label"
                        id="specialty-select"
                        label="Specialty"
                        
                      >
                        {DoctorsSSList.Specialties.map((specialty, index) => (
                          <MenuItem
                            key={index}
                            value={specialty.value}
                            onClick={() =>
                              handleSpecialtyChange(specialty.value)
                            }
                          >
                            {specialty.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <span className=" text-danger" style={{ fontSize: "13px" ,fontFamily: theme.typography.fontFamily, }}>
                    {!!errors.specialty ? errors.specialty?.message : ""}
                  </span>
                </FormControl>

                <FormControl
                  variant="outlined"
                  fullWidth
                  disabled={!selectedSpecialty || user?.subspecialty}
                >
                  <InputLabel id="subspecialty-label">Subspecialty</InputLabel>
                  <Controller
                    name="subspecialty"
                    control={control}
                    defaultValue={user?.subspecialty}
                    rules={{ required: "Sub Specialty is required" }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="subspecialty-label"
                        id="subspecialty-select"
                        label="Subspecialty"
                      >
                        {DoctorsSSList.Specialties.find(
                          (spec) => spec.value === selectedSpecialty
                        )?.subspecialties.map((subspecialty, index) => (
                          <MenuItem key={index} value={subspecialty.value}>
                            {subspecialty.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <span className=" text-danger" style={{ fontSize: "13px" , fontFamily: theme.typography.fontFamily, }}>
                    {!!errors.subspecialty ? errors.subspecialty?.message : ""}
                  </span>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
            mb={5}
          >
            <Grid item xs={3}>
              Any Criminal records ?
            </Grid>
            <Grid item xs={9}>
              <Stack direction="row" width={300}>
                <FormControl>
                  <Controller
                    name="criminalRecords"
                    control={control}
                    defaultValue={user?.criminalRecords ? user?.criminalRecords : "option1" }
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
            mb={5}
          >
            <Grid item xs={3}>
              Are you undergoing any psychiatric assessments?
            </Grid>
            <Grid item xs={9}>
              <Stack direction="row" width={300}>
                <FormControl>
                  <Controller
                    name="psychiatricAssessment"
                    control={control}
                    defaultValue={user?.psychiatricAssessment ? user?.psychiatricAssessment : "option1"}
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      ) : (
        <form
          action=""
          id="editProfile" 
          className="px-4 d-flex flex-column gap-3"
        >
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
          >
            <Grid item xs={3}>
              Name
            </Grid>
            <Grid item xs={9}>
              <div
                className="d-flex flex-column text-secondary"
                style={{ width: "300px" }}
              >
                {user?.salutation}. {user?.name}
              </div>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
          >
            <Grid item xs={3}>
              Gender
            </Grid>
            <Grid item xs={9} textAlign="start">
              <div className="text-secondary">{user?.gender}</div>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
          >
            <Grid item xs={3}>
              Phone Number
            </Grid>
            <Grid item xs={9} textAlign="start">
              <div className="text-secondary">{user?.phone}</div>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
          >
            <Grid item xs={3}>
              Medical License Number
            </Grid>
            <Grid item xs={9} textAlign="start">
              {" "}
              <div className="text-secondary">{user?.medicalLicense}</div>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
          >
            <Grid item xs={3}>
              Clinic Details
            </Grid>
            <Grid item xs={9} textAlign="start">
              <div className="text-secondary">{user?.clinic}</div>
            </Grid>
          </Grid>
          <Divider></Divider>
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
          >
            <Grid item xs={3}>
              Qualification
            </Grid>
            <Grid item xs={9} textAlign="start">
              <div className="text-secondary">{user?.qualification}</div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            textAlign="start"
            direction="row"
            display="flex"
            mb={5}
          >
            <Grid item xs={3}>
              Specialty
            </Grid>
            <Grid item xs={9}>
              <div className="text-secondary">
                {user?.specialty}, {user?.subspecialty}
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
    // </LayoutSidebar>
  );
};

export default AddProfile;
