import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F79009",
      main_light: "#FFFAEB",
    },
    secondary: {
      main: "#dc004e",
    },
    customColors: {
      HoverMain: "#ce790b",
      customColor1: "#F04438",
      customColor2: "#4caf50",
      customColor2Hover: "#3e9141",
      lightestGrey: "#FFFAEB",
      lightGrey: "#d8dce0",
      lighterGrey: "#ECFDF3",
      blue000: "#e6ebfa",
      blue100: "#c2cff3",
      blue200: "#91a9ea",
      blue300: "#5e82e0",
      blue400: "#2e5cd6",
      blue500: "#0038cd",
      blue600: "#0030ae",
      blue700: "#002892",
      greenLeaf: "#ECFDF3",
      darkGreenLeaf: "#DCFAE6",
      lightGreen: "#ABEFC6",
      lighterGreen: "#75E0A7",
      lightestGreen: "#17826A",
      darkGrey: "#475467",
      darkerGrey: "#344054",
      darkestGrey: "#101828",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
        },
      },
    },
  },
});

export const getCustomColor = (colorName) =>
  theme.palette.customColors[colorName];
export default theme;
