import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  Checkbox,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import {
  Close,
  DeleteOutline,
  EditOutlined,
  PersonAddAlt,
} from "@mui/icons-material";
import theme, { getCustomColor } from "../../layout/theme";
import RightPopWindow from "./RightPopWindow";
import Modal from "@mui/material/Modal";
import CommonBotton from "../../components/CommonBotton";
import { useForm } from "react-hook-form";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../FirebaseAuth";
import { useAuth } from "../../middleware/authContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "80%",
    sm: 600,
  },
  bgcolor: "background.paper",
  border: "2px solid offWhite",
  boxShadow: 24,
  borderRadius: "15px",
  px: 4,
  pt: 2,
  pb: 4,
  textAlign: "start",
};

const columns = [
  { id: "name", label: "Name" },
  { id: "dob", label: "DOB" },
  { id: "ic_number", label: "IC Number" },
  { id: "phone", label: "Mobile Number" },
];

const initialRows = [
  {
    id: 1,
    firstName: "Paul",
    lastName: "Jenson",
    icNumber: "SH389217N",
    mobileNumber: "+65 8360 1918",
  },
  {
    id: 2,
    firstName: "Rhiel",
    lastName: "Madsen",
    icNumber: "T0389217F",
    mobileNumber: "+65 9278 1974",
  },
  {
    id: 3,
    firstName: "Ryan",
    lastName: "Westervelt",
    icNumber: "T0389217F",
    mobileNumber: "+65 9278 1974",
  },
  {
    id: 4,
    firstName: "Phillip",
    lastName: "Gouse",
    icNumber: "T0389217F",
    mobileNumber: "+65 9278 1974",
  },
  {
    id: 5,
    firstName: "Roger",
    lastName: "Culhane",
    icNumber: "T0389217F",
    mobileNumber: "+65 9278 1974",
  },
  {
    id: 6,
    firstName: "Terry",
    lastName: "Schleifer",
    icNumber: "T0389217F",
    mobileNumber: "+65 9278 1974",
  },
  {
    id: 7,
    firstName: "Ahmad",
    lastName: "Bergson",
    icNumber: "T0389217F",
    mobileNumber: "+65 9278 1974",
  },
  {
    id: 8,
    firstName: "Michael",
    lastName: "Dokidis",
    icNumber: "T0389217F",
    mobileNumber: "+65 9278 1974",
  },
  {
    id: 9,
    firstName: "Craig",
    lastName: "Dias",
    icNumber: "T0389217F",
    mobileNumber: "+65 9278 1974",
  },
  {
    id: 10,
    firstName: "Carlo",
    lastName: "Leen",
    icNumber: "T0389217F",
    mobileNumber: "+65 9278 1974",
  },
];
const Patients = () => {
  const { currentUser } = useAuth();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const getAddedUsers = async () => {
    if (currentUser) {
      const addedUsersRef = collection(db, "patients");
      const q = query(addedUsersRef, where("addedBy", "==", currentUser.uid)); // Query to filter by addedBy field

      try {
        const snapshot = await getDocs(q);
        const users = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRows(users);
        return users;
      } catch (e) {
        console.error("Error retrieving users: ", e);
      }
    } else {
      console.error("No user is signed in.");
    }
  };

  useEffect(() => {
    currentUser?.uid && getAddedUsers();
  }, [currentUser?.uid]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset page when search query changes
  };

  const handleSelectRow = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const isSelected = (id) => selectedRows.includes(id);

  const filteredRows = rows.filter((row) => {
    const matchesSearchQuery = Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );
    return matchesSearchQuery;
  });

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [details, setdetailstate] = useState();

  const toggleChat = (detail) => {
    setIsChatOpen(!isChatOpen);
    setdetailstate(detail);
  };
  const closeChat = () => {
    setIsChatOpen(false);
  };

  const handleDelete = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  // update  patient modal
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);
  const [UpdateData, setUpdateData] = React.useState([]);

  const handleopenUpdateModal = (data) => {
    setOpenUpdateModal(true);
    setUpdateData(data);
  };
  const handleClosenUpdateModal = () => {
    setOpenUpdateModal(false);
  };

  const {
    register: registerUpdate,
    handleSubmit: handleSubmitUpdate,
    formState: { errors: errorsUpdate },
  } = useForm();

  const onSubmitUpdate = async (data) => {
    const newCollectionRef = doc(db, "patients", UpdateData.id);
    try {
      await setDoc(
        newCollectionRef,
        {
          name: data.fullName,
          dob: data.dob,
          phone: data.contactNumber,
          gender: data.gender,
          ic_number: data.IC_Number,
          address: data.address,
          addedBy: currentUser.uid,
          updateAt: new Date(),
        },
        { merge: true }
      );
      getAddedUsers();
      handleClosenUpdateModal();
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    // You can send data to your server here
  };

  // add patient modal
  const [openCallEndModal, setOpenCallEndModal] = React.useState(false);

  const handleOpenCallEndModal = () => {
    setOpenCallEndModal(true);
  };
  const handleClosenCallEndModal = () => {
    setOpenCallEndModal(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const newCollectionRef = collection(db, "patients");
    try {
      const docRef = await addDoc(newCollectionRef, {
        name: data.fullName,
        dob: data.dob,
        phone: data.contactNumber,
        gender: data.gender,
        ic_number: data.IC_Number,
        address: data.address,
        addedBy: currentUser.uid, // Optional: store who added the user
        createdAt: new Date(),
      });
      // console.log("Document written with ID: ", docRef.id);
      getAddedUsers();
      handleClosenCallEndModal();
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    // You can send data to your server here
  };
  const deleteUserById = async (userId) => {
    const docRef = doc(db, "patients", userId); // Adjust 'addedUsers' to your collection name

    try {
      await deleteDoc(docRef);
      getAddedUsers();
      // console.log("Document successfully deleted!");
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  return (
    <div>
      <Box m={3} mx={1}> 
        <Paper
          sx={{
            borderRadius: "10px",
          }}
        >
          <div className="d-flex align-items-center justify-content-between  pe-2">
            <h5 className="text-start p-2">
              Patients ( {filteredRows.length} )
            </h5>
            <CommonBotton
              text="Add Patient"
              icon={<PersonAddAlt />}
              onClick={handleOpenCallEndModal}
            />
          </div>
          <TextField
            size="small"
            fullWidth
            placeholder={`🔍 Search`}
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{
              pb: 2,
            }}
            // style={{ margin: "1rem" }}
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    background: getCustomColor("lightGrey"),
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < rows.length
                      }
                      checked={
                        rows.length > 0 && selectedRows.length === rows.length
                      }
                      onChange={(event) => {
                        if (event.target.checked) {
                          const newSelecteds = rows.map((row) => row.id);
                          setSelectedRows(newSelecteds);
                        } else {
                          setSelectedRows([]);
                        }
                      }}
                    />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell key={column.id}>{column.label}</TableCell>
                  ))}
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      key={row.id}
                      selected={isSelected(row.id)}
                      sx={{
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isSelected(row.id)}
                          onChange={() => handleSelectRow(row.id)}
                        />
                      </TableCell>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          onClick={() => toggleChat(row)}
                        >
                          {row[column.id]}
                        </TableCell>
                      ))}
                      <TableCell>
                        <IconButton>
                          <DeleteOutline
                            onClick={() => deleteUserById(row.id)}
                          />
                        </IconButton>
                        <IconButton>
                          <EditOutlined
                            onClick={() => handleopenUpdateModal(row)}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count, page }) => {
              const totalPages = Math.ceil(count / rowsPerPage);
              return `${from}-${to} of ${count} (Page ${
                page + 1
              } of ${totalPages})`;
            }}
            sx={{
              ".css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar > p": {
                margin: "0 !important",
              },
            }}
          />
        </Paper>
      </Box>

      {isChatOpen && (
        <RightPopWindow
          isOpen={isChatOpen}
          OppUser={details}
          toggleChat={toggleChat}
          closeChat={closeChat}
        />
      )}

      <Modal
        open={openCallEndModal}
        onClose={handleClosenCallEndModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Box sx={style}>
            <Box>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <Typography variant="h5">Patient Registration</Typography>
                <p className="mb-0 text-end hover">
                  <Close onClick={() => handleClosenCallEndModal()} />
                </p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-12 col-sm-6 mb-2">
                    <TextField
                      fullWidth
                      label="Full Name"
                      {...register("fullName", {
                        required: "Full name is required",
                      })}
                      error={!!errors.fullName}
                      helperText={
                        errors.fullName ? errors.fullName.message : ""
                      }
                    />
                  </div>

                  <div className="col-12 col-sm-6 mb-2">
                    <TextField
                      fullWidth
                      label="Date of Birth"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      {...register("dob", {
                        required: "Date of birth is required",
                      })}
                      error={!!errors.dob}
                      helperText={errors.dob ? errors.dob.message : ""}
                    />
                  </div>

                  <div className="col-12 col-sm-6 mb-2">
                    <TextField
                      fullWidth
                      type="tel"
                      label="Contact Number"
                      variant="outlined"
                      {...register("contactNumber", {
                        required: "Contact number is required",
                        pattern: {
                          value: /^[0-9]{10}$/, // Adjust pattern for Singapore phone numbers (8 digits)
                          message: "Please enter a valid 10-digit phone number",
                        },
                      })}
                      error={!!errors.contactNumber}
                      helperText={
                        errors.contactNumber ? errors.contactNumber.message : ""
                      }
                    />
                  </div>

                  <div className="col-12 col-sm-6 mb-2">
                    <FormControl fullWidth>
                      <InputLabel>Gender</InputLabel>
                      <Select
                        {...register("gender", {
                          required: "Gender is required",
                        })}
                        error={!!errors.gender}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                      {errors.gender && (
                        <span
                          className=" text-danger"
                          style={{ fontSize: "13px" , fontFamily: theme.typography.fontFamily, }}
                        >
                          {!!errors.gender ? errors.gender?.message : ""}
                        </span>
                      )}
                    </FormControl>
                  </div>
                  <div className="col-12 col-sm-6 mb-2">
                    <TextField
                      fullWidth
                      label="IC Number"
                      {...register("IC_Number", {
                        required: "IC Number is required",
                      })}
                      error={!!errors.IC_Number}
                      helperText={
                        errors.IC_Number ? errors.IC_Number.message : ""
                      }
                    />
                  </div>
                  <div className="col-12 col-sm-6 mb-2">
                    <TextField
                      fullWidth
                      label="Address"
                      multiline
                      {...register("address")}
                    />
                  </div>
                </div>
                <CommonBotton text="Register" type="submit" />
              </form>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openUpdateModal}
        onClose={handleClosenUpdateModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Box sx={style}>
            <Box>
              <div className="d-flex align-items-center justify-content-between mb-3">
                <Typography variant="h5" sx={{  fontFamily: theme.typography.fontFamily,}}>Update Patient</Typography>
                <p className="mb-0 text-end hover">
                  <Close onClick={() => handleClosenUpdateModal()} />
                </p>
              </div>
              <form onSubmit={handleSubmitUpdate(onSubmitUpdate)}>
                <div className="row">
                  <div className="col-12 col-sm-6 mb-2">
                    <TextField
                      fullWidth
                      label="Full Name"
                      defaultValue={UpdateData.name}
                      {...registerUpdate("fullName", {
                        required: "Full name is required",
                      })}
                      error={!!errorsUpdate.fullName}
                      helperText={
                        errorsUpdate.fullName
                          ? errorsUpdate.fullName.message
                          : ""
                      }
                    />
                  </div>

                  <div className="col-12 col-sm-6 mb-2">
                    <TextField
                      fullWidth
                      label="Date of Birth"
                      type="date"
                      defaultValue={UpdateData.dob}
                      InputLabelProps={{ shrink: true }}
                      {...registerUpdate("dob", {
                        required: "Date of birth is required",
                      })}
                      error={!!errorsUpdate.dob}
                      helperText={
                        errorsUpdate.dob ? errorsUpdate.dob.message : ""
                      }
                    />
                  </div>

                  <div className="col-12 col-sm-6 mb-2">
                    <TextField
                      fullWidth
                      type="tel"
                      label="Contact Number"
                      variant="outlined"
                      defaultValue={UpdateData.phone}
                      {...registerUpdate("contactNumber", {
                        required: "Contact number is required",
                        pattern: {
                          value: /^[0-9]{10}$/, // Adjust pattern for Singapore phone numbers (8 digits)
                          message: "Please enter a valid 10-digit phone number",
                        },
                      })}
                      error={!!errorsUpdate.contactNumber}
                      helperText={
                        errorsUpdate.contactNumber
                          ? errorsUpdate.contactNumber.message
                          : ""
                      }
                    />
                  </div>

                  <div className="col-12 col-sm-6 mb-2">
                    <FormControl fullWidth>
                      <InputLabel>Gender</InputLabel>
                      <Select
                        {...registerUpdate("gender", {
                          required: "Gender is required",
                        })}
                        error={!!errorsUpdate.gender}
                        defaultValue={UpdateData.gender}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                      {errorsUpdate.gender && (
                        <span
                          className=" text-danger"
                          style={{ fontSize: "13px", fontFamily: theme.typography.fontFamily, }}
                        >
                          {!!errorsUpdate.gender
                            ? errorsUpdate.gender?.message
                            : ""}
                        </span>
                      )}
                    </FormControl>
                  </div>
                  <div className="col-12 col-sm-6 mb-2">
                    <TextField
                      fullWidth
                      label="IC Number"
                      defaultValue={UpdateData.ic_number}
                      {...registerUpdate("IC_Number", {
                        required: "IC Number is required",
                      })}
                      error={!!errorsUpdate.IC_Number}
                      helperText={
                        errorsUpdate.IC_Number
                          ? errorsUpdate.IC_Number.message
                          : ""
                      }
                    />
                  </div>
                  <div className="col-12 col-sm-6 mb-2">
                    <TextField
                      fullWidth
                      label="Address"
                      defaultValue={UpdateData.address}
                      multiline
                      {...registerUpdate("address")}
                    />
                  </div>
                </div>
                <CommonBotton text="Register" type="submit" />
              </form>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Patients;
