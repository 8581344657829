import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Drawer,
  Avatar,
  Box,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import styled from "styled-components";
import {
  arrayUnion,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../FirebaseAuth";
import { ArrowBack, AttachFile, Close } from "@mui/icons-material";
import Message from "./Components/Message";
import SendingFile from "./Components/sendingFile/SendingFile";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { FiSend } from "react-icons/fi";
import theme from "../../layout/theme";
import JitsiMeetPopup from "../Jitsi/JitsiMeetPopup";
import dp from "../../assets/Steve.png";
import axios from "axios";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const chatId = (user1, user2) => {
  if (user1.toLowerCase().codePointAt(0) > user2.toLowerCase().codePointAt(0)) {
    return `${user1} & ${user2}`;
  } else {
    return `${user2} & ${user1}`;
  }
};
const ChatWindow = ({ isOpen, toggleChat, OppUser, closeChat, myData }) => {
  //   const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  //   const [file, setFile] = useState(null);

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const [file, setFile] = useState([]);

  // myChat
  const findChat = async (id) => {
    const unSub = onSnapshot(doc(db, "chats", id), (doc) => {
      doc.exists() && setMessages(doc.data().messages);
    });
    return () => {
      unSub();
    };
  };

  useEffect(() => {
    //   dispatch(fetchAllMsgs(myData.id, chatRecipient.id));
    findChat(chatId(myData.name, OppUser.name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.toLocaleString("default", {
      month: "short",
    })} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const groupedMessages = {};

  messages.forEach((message) => {
    const date = formatDate(message.created_at);
    if (!groupedMessages[date]) {
      groupedMessages[date] = [];
    }
    groupedMessages[date].push(message);
  });

  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    // Scroll to bottom when messages change
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        bottom: 100,
        behavior: "smooth",
      });
    }
  }, [messages]);

  // useEffect(() => {
  //   if (isAtBottom && messagesEndRef.current) {
  //     messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [messages, isAtBottom]);

  const handleScroll = () => {
    const container = chatContainerRef.current;
    if (!container) return;

    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    // Check if the user is at the bottom (or close to it)
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 50; // Adjust the threshold as needed
    setIsAtBottom(isAtBottom);
  };
  const [fileProgress, setFileProgress] = useState({});
  const [fileCount, setFileCount] = useState();

  const handleSendImage = async (img) => {
    const files = Array.from(img);
    files.forEach((file) => {
      const type = file.type.split("/").shift();
      let ab = file.name;
      let thumbName = ab.split(".").shift();
      let cc = ab.split(".").pop();
      const metadata = {
        contentType: "application/pdf", // Set the content type of the file
      };
      const storageRef = ref(storage, ab);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setFileProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: progress,
          }));
          // console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.log("Something went wrong!" + error.code);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            if (type === "image") {
              handleSendMsg(ab, downloadURL, "", "");
            } else if (type === "video") {
              handleSendMsg(ab, "", downloadURL, "");
            } else {
              handleSendMsg(ab, "", "", downloadURL, cc);
            }
            setFileCount((prevCount) => prevCount - 1);
            if (fileCount === 1) {
              // Reset file state only when all files are sent
              setFile([]);
            }
          });
        }
      );
    });
  };

  useEffect(() => {
    // Check if all files have been sent
    if (fileCount === 0 && file !== null) {
      // Reset file state to null
      setFile([]);
    }
  }, [fileCount, file]);

  // console.log(file, ":file");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files;
    handleSendImage(selectedFile);
    setFile([...file, ...event.target.files]);
    setFileCount(event.target.files.length);
  };

  const handleSendMsg = async (
    fileName,
    imageUrl,
    videoUrl,
    fileUrl,
    fileType
  ) => {
    if (message) {
      inputRef.current.focus();
    }
    const myMsg = {
      sender_id: myData.id,
      send_by: myData.name,
      message: imageUrl
        ? imageUrl
        : videoUrl
        ? videoUrl
        : fileUrl
        ? fileUrl
        : message,
      type: imageUrl ? "img" : videoUrl ? "vid" : fileUrl ? fileType : "text",
      fileName: fileName ? fileName : "",
      created_at: Date.now(),
    };

    messagesEndRef.current?.scrollIntoView({ bottom: 0, behavior: "instant" });
    if (input.trim() !== "") {
      messages.push(myMsg);
      setInput("");
      setMessage("");
    }

    try {
      const res = await getDoc(
        doc(db, "chats", chatId(myData.name, OppUser.name))
      );
      if (res.exists()) {
        try {
          await updateDoc(doc(db, "chats", chatId(myData.name, OppUser.name)), {
            messages: arrayUnion({
              ...myMsg,
            }),
          });

          // await updateDoc(doc(db, "chats", chatDocId2), {
          //   messages: arrayUnion({
          //     ...myMsg,
          //   }),
          // });

          // await addDoc(collection(db, "chats", chatDocId1, "messages"), {
          //   myMsg,
          // });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          await setDoc(doc(db, "chats", chatId(myData.name, OppUser.name)), {
            messages: arrayUnion({
              ...myMsg,
            }),
          });
          // if (imageUrl || videoUrl || fileUrl) {
          //   setFile(null);
          // }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }

    //   await updateDoc(doc(db, "chats", chatDocId), { msgInfo });
  };

  const sendNotification = async (token, title, body) => {
    try {
      const res = await axios.post("http://localhost:5000/send-notification", {
        token: token,
        title: `${title} is Calling you..`,
        body: body,
      });
      if (res.statusCode === 200) {
        console.log("Notification sent:");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Drawer
      className="lower-div"
      variant="permanent"
      anchor="right"
      open={isOpen}
      onClose={() => {}}
      ModalProps={{
        keepMounted: true, // Better performance on mount
      }}
    >
      {/* <Box position="fixed" right="0px" top="0px" height="100vh"> */}
      <Box
        position="relative"
        width={{
          xs: "400px",
          sm: "400px",
        }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mx="auto"
        zIndex="100"
      >
        <AppBar
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "sticky",
            p: 0.5,
            backgroundColor: "#FFFFFF",
            color: "#000",
          }}
        >
          <Toolbar
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "start",
              p: 2,
            }}
          >
            <Box
              sx={{
                textAlign: "left",
              }}
              // onClick={() =>
              //   sendNotification(OppUser.deviceToken, myData.name, "test")
              // }
            >
              {/* <Link to="/videocall" state={ { name : OppUser.name , image : OppUser.dp }}>
                <VideoCallRounded sx={{ color: "white" }} />
              </Link> */}
              {/* <JitsiMeetPopup roomName="HiveMeetingRoom" name={OppUser.name} /> */}
              <Typography
                sx={{
                  color: "#101828",
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "28px",
                  textAlign: "left",
                }}
              >
                Live chat
              </Typography>
              <Typography
                sx={{
                  color: "#475467",
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  textAlign: "left",
                }}
              >
                This conversation will automatically end with the call, but you
                can review the details in the call log for reference and
                continuity of care.
              </Typography>
            </Box>
            <Close
              sx={{ cursor: "pointer" , fontFamily: theme.typography.fontFamily, }}
              fontSize="medium"
              //   onClick={() => navigate(-1)}
              onClick={closeChat}
            />
          </Toolbar>
        </AppBar>
        <div style={{ mb: 10 }} ref={chatContainerRef} onScroll={handleScroll}>
          {Object.entries(groupedMessages).map(([date, messages]) => (
            <div key={date}>
              <div className="text-center fs-6 pb-2">{date}</div>
              {messages.map((message, i) => (
                <Message
                  messages={message}
                  key={i}
                  mId={i}
                  myData={myData}
                  OppUser={OppUser}
                />
              ))}
            </div>
          ))}
          <div
            className=""
            ref={messagesEndRef}
            style={{
              marginBottom: "60px",
            }}
          ></div>
        </div>
        <Box
          sx={{
            bgcolor: "white",
            width: {
              xs: "400px",
              sm: "400px",
            },
            position: "fixed",
            bottom: 0,
            zIndex: 1,
            pb: 1,
            pt: 1,
          }}
        >
          {file.length > 0 ? (
            <SendingFile
              show={file}
              content={file}
              progress={Object.values(fileProgress || 0)}
            />
          ) : null}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { sm: "start", xs: "space-evenly" },
            }}
          >
            <form
              action=""
              className="d-flex align-items-center gap-2 mx-auto"
              onSubmit={(e) => handleSendMsg(e.preventDefault())}
            >
              <TextField
                type={"text"}
                size="small"
                fullWidth
                ref={inputRef}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  setInput(e.target.value);
                }}
                sx={{ width: { sm: 250, xs: 200 } }}
                variant="outlined"
              />
              {/* <Button endIcon={<AttachFile />} tabIndex={-1} component="label">
                {" "}
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  accept="image/* , video/mp4 , application/pdf ,application/msword , text/plain ,.doc, .docx"
                  // onChange={(e) => handleSendImage(e.target.files[0])}
                  // onChange={(e) => {
                  //   handleSendImage(e.target.files[0]);
                  //   handleFileChange(e.target.files);
                  //   setFile(e.target.files[0]);
                  // }}
                  onChange={handleFileChange}
                ></VisuallyHiddenInput>
              </Button> */}
              <IconButton tabIndex={-1} component="label"> 
              <AttachFile />
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  accept="image/* , video/mp4 , application/pdf ,application/msword , text/plain ,.doc, .docx"
                  // onChange={(e) => handleSendImage(e.target.files[0])}
                  // onChange={(e) => {
                  //   handleSendImage(e.target.files[0]);
                  //   handleFileChange(e.target.files);
                  //   setFile(e.target.files[0]);
                  // }}
                  onChange={handleFileChange}
                ></VisuallyHiddenInput>
              </IconButton>
              <Button
                onClick={() => handleSendMsg()}
                onKeyDown={(event) => {
                  event.preventDefault();
                }}
                disabled={message === ""}
                sx={{
                  color: "white",
                  backgroundColor: theme.palette.primary.main,
                }}
              >
                <FiSend fontSize="20px" />
              </Button>
              {/* <IconButton aria-label="send" onClick={() => handleSendMsg()}
                onKeyDown={(event) => {
                  event.preventDefault();
                }}
                disabled={message === ""}
                sx={{ color: theme.palette.primary.main, padding: 0 }}>
                <FiSend fontSize="22px" />
              </IconButton> */}
            </form>
          </Box>
        </Box>
      </Box>
      {/* </Box> */}
    </Drawer>
  );
};

export default ChatWindow;
