import React, { useEffect, useState } from "react";
import TableWithPagination from "../../components/DataTable";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  TextField,
  IconButton,
} from "@mui/material";
import { Call, Circle, Close, Flag } from "@mui/icons-material";
import BackImage from "../../assets/pexels-photo-4230617.jpeg";
import MyCarousel from "../../components/MyCarousel";
import theme, { getCustomColor } from "../../layout/theme";
import CommonBotton from "../../components/CommonBotton";
import { useSearch } from "../../middleware/searchContext";
import CallLogsTable from "../../components/CallLogs";
import { useAuth } from "../../middleware/authContext";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../FirebaseAuth";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const columns = [
  { id: "name", label: "Patient Name" },
  { id: "specialist", label: "Specialist" },
  { id: "date", label: "Date" },
  { id: "status", label: "Status" },
];

const rows = [
  {
    id: 1,
    name: "John Doe",
    specialist: "Cardiology",
    date: "Dec 21, 2023 (wed) 1:00pm",
    status: "Pending",
  },
  {
    id: 2,
    name: "Jane Smith",
    specialist: "Radiology",
    date: "Dec 22, 2023 (Mon) 7:29pm",
    status: "Referred",
  },
  {
    id: 3,
    name: "Alice Johnson",
    specialist: "Cardiology",
    date: "Dec 23, 2023  (Mon) 9:09am",
    status: "Call on Hold",
  },
  {
    id: 4,
    name: "Bob Brown",
    specialist: "Cardiology",
    date: "Dec 25, 2023  (wed) 11:29am",
    status: "Active",
  },
  {
    id: 5,
    name: "Emma Wilson",
    specialist: "Cardiology",
    date: "Jan 21, 2023  (Thur) 7:29pm",
    status: "Referred",
  },
  {
    id: 6,
    name: "James Taylor",
    specialist: "Cardiology",
    date: "Feb 21, 2024  (Fri) 02:29pm",
    status: "Active",
  },
  {
    id: 7,
    name: "Olivia Clark",
    specialist: "Cardiology",
    date: "Feb 22, 2024  (Mon) 7:29pm",
    status: "Active",
  },
  {
    id: 8,
    name: "William Lee",
    specialist: "Radiology",
    date: "Feb 24, 2024  (Tus) 12:29pm",
    status: "Call Ended",
  },
  {
    id: 9,
    name: "Sophia Martinez",
    specialist: "Cardiology",
    date: "Feb 20, 2024  (Mon) 7:29pm",
    status: "Referred",
  },
  {
    id: 10,
    name: "Daniel Harris",
    specialist: "Radiology",
    date: "Feb 22, 2024 (Mon) 7:29pm",
    status: "Referred",
  },
];

//call logs

const callHeaders = [
  { id: "name", label: "Dr. Name" },
  { id: "specialist", label: "Specialist" },
  { id: "date", label: "Date" },
  { id: "duration", label: "Duration" },
];

const callData = [
  {
    id: 1,
    name: "John Doe",
    specialist: "Cardiology",
    date: "Dec 21, 2023 (wed) 1:00pm",
    duration: "13 min",
  },
  {
    id: 2,
    name: "Jane Smith",
    specialist: "Radiology",
    date: "Dec 22, 2023 (Mon) 7:29pm",
    duration: "10 min 50 sec",
  },
  {
    id: 3,
    name: "Alice Johnson",
    specialist: "Cardiology",
    date: "Dec 23, 2023  (Mon) 9:09am",
    duration: "20 min",
  },
  {
    id: 4,
    name: "Bob Brown",
    specialist: "Cardiology",
    date: "Dec 25, 2023  (wed) 11:29am",
    duration: "8 min 20 sec",
  },
  {
    id: 5,
    name: "Emma Wilson",
    specialist: "Cardiology",
    date: "Jan 21, 2023  (Thur) 7:29pm",
    duration: "25 min 10 sec",
  },
  {
    id: 6,
    name: "James Taylor",
    specialist: "Cardiology",
    date: "Feb 21, 2024  (Fri) 02:29pm",
    duration: "15 min",
  },
  {
    id: 7,
    name: "Olivia Clark",
    specialist: "Cardiology",
    date: "Feb 22, 2024  (Mon) 7:29pm",
    duration: "10 min",
  },
  {
    id: 8,
    name: "William Lee",
    specialist: "Radiology",
    date: "Feb 24, 2024  (Tus) 12:29pm",
    duration: "12 min",
  },
  {
    id: 9,
    name: "Sophia Martinez",
    specialist: "Cardiology",
    date: "Feb 20, 2024  (Mon) 7:29pm",
    duration: "13 min",
  },
  {
    id: 10,
    name: "Daniel Harris",
    specialist: "Radiology",
    date: "Feb 22, 2024 (Mon) 7:29pm",
    duration: "15 min 20 sec",
  },
];

const HomeScreen = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const specialists = [
    { name: "Radiologists", count: 2, online: true },
    { name: "Pediatrist", count: 3, online: true },
    { name: "Cardiologist", count: 6, online: true },
    { name: "Neurologist", count: 2, online: false },
    { name: "Psychiatrist", count: 4, online: true },
    { name: "Oncologists", count: 4, online: true },
    { name: "Urologist", count: 1, online: true },
    { name: "Orthopaedist", count: 0, online: false },
  ];
  const filteredSpecialists = specialists.filter((specialist) =>
    specialist.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const [showCompleteProfileBadge, setCompleteProfileBadge] = useState(false);

  const { currentUser } = useAuth();
  const [user, setUserData] = useState();
  useEffect(() => {
    const getChats1 = () => {
      const unsub1 = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        // setUserData({ ...doc.data(), id: currentUser.uid });
        const data = doc.data();
        const requiredFields = [
          "profilePicture",
          "gender",
          "name",
          "clinic",
          "clinicAddress",
          "specialty",
          "subspecialty",
          "medicalLicense",
        ];
        const isProfileIncomplete = requiredFields.some(
          (field) => !data[field]
        );
        setCompleteProfileBadge(isProfileIncomplete);
      });

      return () => {
        unsub1();
      };
    };
    currentUser.uid && getChats1();
  }, [currentUser?.uid]);

  //call logs
  const [searchlog, setSearchLog] = useState("");
  const handleSearchLog = (event) => {
    setSearchLog(event.target.value);
  };

  // const sendNotification = async (deviceToken) => {
  //   const message = {
  //     notification: {
  //       title: "Hello!",
  //       body: "This is a test notification.",
  //     },
  //     token: deviceToken,
  //   };

  //   const response = await fetch("https://fcm.googleapis.com/fcm/send", {
  //     method: "POST",
  //     headers: {
  //       Authorization: "key=",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(message),
  //   });

  //   if (response.ok) {
  //     const data = await response.json();
  //     console.log("Notification sent successfully:", data);
  //   } else {
  //     console.error("Error sending notification:", response.statusText);
  //   }
  // };

  return (
    <>
      {showCompleteProfileBadge === true ? (
        <Box
          mt={3}
          mx={3}
          sx={{
            border: "1px solid",
            borderColor: "#FEC84B",
            borderRadius: "10px",
            backgroundColor: theme.palette.primary.main_light,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="d-flex align-items-center">
            <IconButton
              sx={{
                color: theme.palette.primary.main,
                backgroundColor: "#f5df9d",
                margin: 2,
              }}
            >
              <Flag />
            </IconButton>
            <div className="text-start">
              <p
                className="mb-0"
                style={{
                  color: "#B54708",
                  fontWeight: "501",
                }}
              >
                Complete your profile
              </p>
              <p
                className="mb-0"
                style={{
                  color: theme.palette.primary.main,
                }}
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
            </div>
          </div>
          <div className="">
            <CommonBotton
              text="Go to my profile"
              onClick={() => navigate("/profile")}
            />
            <IconButton
              sx={{
                color: theme.palette.primary.main,
                marginX: 1,
              }}
              onClick={() => setCompleteProfileBadge(false)}
            >
              <Close />
            </IconButton>
          </div>
        </Box>
      ) : null}

      <Box p={3}>
        <Box
          sx={{
            background: `url(${BackImage}) no-repeat center  fixed`,
            backgroundSize: "cover",
            width: "100%",
            height: "350px",
            display: "flex",
            justifyContent: "start",
            position: "relative",
            borderRadius: "10px",

            "::after": {
              content: "''",
              position: "absolute",
              top: 0,
              bottom: 0,
              left: "0%",
              width: "80%",
              background:
                "linear-gradient(to right, rgba(0, 0, 0, 0.818), rgba(0, 0, 0, 0))",
              mixBlendMode: "multiply",
              borderRadius: "10px", // Adjust border radius to match container
              boxShadow: "0px 0 0px rgba(0, 0, 0, 0.3)", // Adjust shadow as needed
            },
          }}
        >
          <Box
            mx={2}
            my={5}
            sx={{
              zIndex: 10,
              color: "white",
            }}
          >
            <Typography
              variant="h3"
              component="h6"
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontSize: "36px",
                fontWeight: "600",
                lineHeight: "44px",
                letterSpacing: "-0.02em",
                textAlign: "start",
              }}
            >
              Find, consult and refer. Fast.
            </Typography>
            <Typography
              my={1}
              sx={{
                width: "70%",
                fontSize: "20px",
                textAlign: "start",
                fontFamily: theme.typography.fontFamily,
                fontWeight: "400",
                lineHeight: "30px",
              }}
            >
              Powerful, self-serve platform to help you and find, engage and
              refer.
            </Typography>
            <Box
              sx={{
                width: "80%",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <TextField
                size="small"
                variant="outlined"
                value={searchQuery}
                placeholder="Search Specialty"
                onChange={handleSearchChange}
                style={{
                  margin: "1rem 1rem 1rem 0",
                  width: "100%",
                  color: "white",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
              />
              <Button
                variant="contained"
                sx={{
                  background: theme.palette.primary.main,
                  "&:hover": {
                    background: getCustomColor("HoverMain"),
                  },
                }}
              >
                Search
              </Button>
            </Box>
            {/* <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))", // Adjust chip size here
                // gap: "8px",
              }}
            >
              {chipData.map((data) => {
                return (
                  // <ListItem
                  //   key={data.key}
                  // >
                  <Chip
                    label={data.label}
                    variant="outlined"
                    style={{
                      margin: "8px",
                      // border: 'solid 1px gray',
                      backgroundColor: selectedChips.includes(data.key)
                        ? "orange"
                        : "inherit",
                      color: selectedChips.includes(data.key)
                        ? "white"
                        : "inherit",
                    }}
                    clickable
                    onClick={() => handleChipClick(data.key)}
                  />
                  // </ListItem>
                );
              })}
            </Box> */}
          </Box>
        </Box>
      </Box>

      <Box
        m={2}
        sx={{
          border: "solid 1px #ccc",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          textAlign="start"
          sx={{
            backgroundColor: "#f0efef",
            borderBottom: "1px solid #e5e4e4",
            borderRadius: "10px 0px 0px 10px ",
            padding: "15px",
            color: "black",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            width: "200px",
            minHeight: "120px",
          }}
        >
          <p
            className="mb-0"
            // onClick={() =>
            //   sendNotification(
            //     "eI1NzsYKmermJ60cIhpNfW:APA91bEVoHulSyRxezKk8Tc2TuwfQpWT96lyDNfEub0bIg9b5TPki_JHzpdKOQqyZBVcaT_5AKwUTVpCuEUVMuEz09D7rFPQ-LAmXs6Vi44E47rSdhYSxrV3E6IbYcX52wSncMp5oHbV",
            //     "HI",
            //     "OD"
            //   )
            // }
          >
            Quick Call
          </p>
        </Box>
        <Box
          m={3}
          mx={1}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            className="d-flex"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "start",
              gap: "16px",
            }}
          >
            {filteredSpecialists.length > 0 ? (
              filteredSpecialists.map((specialist, index) => (
                <Stack
                  key={index}
                  direction="row"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: {
                      xs: "space-between",
                      sm: "space-between",
                      md: "none",
                    },
                    width: {
                      xs: "100%",
                      md: "auto",
                    },
                  }}
                >
                  <Circle
                    sx={
                      specialist.count !== 0
                        ? {
                            color: "green",
                            fontSize: "15px",
                            margin: "5px",
                          }
                        : {
                            color: "gray",
                            fontSize: "15px",
                            margin: "5px",
                          }
                    }
                  />
                  <p className="mb-0">{specialist.name}</p>
                  <span
                    className="mx-2"
                    style={{
                      fontFamily: theme.typography.fontFamily,
                      fontSize: "12px",
                      color: "gray",
                    }}
                  >{`${specialist.count} online`}</span>
                  <Button
                    variant="outlined"
                    disabled={specialist.count === 0}
                    color="success"
                    sx={
                      specialist.count !== 0
                        ? {
                          fontFamily: theme.typography.fontFamily,
                            fontSize: "10px",
                            backgroundColor: "#0080002e",
                            border: "none",
                            width: "50px",
                            minWidth: "50px",
                          }
                        : {
                          fontFamily: theme.typography.fontFamily,
                            fontSize: "10px",
                            backgroundColor: "transparent",
                            border: "none",
                            width: "50px",
                            minWidth: "50px",
                          }
                    }
                    onClick={() =>
                      navigate(`/newcall?specialist=${specialist.name}`)
                    }
                  >
                    <Call
                      sx={{
                        fontSize: "12px",
                      }}
                    />
                  </Button>
                </Stack>
              ))
            ) : (
              <Typography
                variant="body2"
                sx={{ padding: "10px" ,  fontFamily: theme.typography.fontFamily,}}
              >
                No specialists found.
              </Typography>
            )}
          </div>
        </Box>
      </Box>

      <Box
        m={2}
        sx={{
          flexGrow: 1,
        }}
      >
        <Grid container spacing={1} sx={{}}>
          <Grid
            xs={12}
            sm={12}
            md={9}
            item
            sx={{
              height: "100%",
            }}
          >
            <Box
              my={2}
              sx={{
                border: "solid 1px #ccc",
                borderRadius: "10px",
              }}
            >
              <Box
                textAlign="start"
                sx={{
                  backgroundColor: "#f0efef",
                  borderBottom: "1px solid #e5e4e4",
                  borderRadius: "10px 10px 0px 0px ",
                  padding: "10px",
                  color: "#989696",
                  display: "flex",
                  alignItems: "center",
                  verticalAlign: "middle",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: "600",
                    fontSize: "18px",
                    lineHeight: "28px",
                    color: "black",
                  }}
                >
                  Case records
                </Typography>
                <div
                  className="border border-secondary px-1 mx-2"
                  style={{
                    color: "#344054",
                    borderRadius: "5px",
                    fontSize: "12px",
                    fontFamily: theme.typography.fontFamily,
                    fontWeight: "600",
                    background: "white",
                  }}
                >
                  {rows.length} Patients
                </div>
              </Box>
              <Box>
                <TableWithPagination
                  rows={rows}
                  columns={columns}
                  searchData={searchQuery}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={3}
            item
            sx={{
              height: "100%",
            }}
          >
            <Box my={2}>
              <MyCarousel />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        m={2}
        sx={{
          border: "solid 1px #ccc",
          borderRadius: "10px",
        }}
      >
        <Box
          textAlign="start"
          sx={{
            backgroundColor: "#f0efef",
            borderBottom: "1px solid #e5e4e4",
            borderRadius: "10px 10px 0px 0px ",
            padding: "10px",
            color: "#989696",
            display: "flex",
            alignItems: "center",
            verticalAlign: "middle",
            justifyContent: "space-between",
          }}
        >
          <div className="d-flex align-items-center ">
            <Typography
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "28px",
                color: "black",
              }}
            >
              Call Logs
            </Typography>
            <div
              className="border border-secondary px-1 mx-2"
              style={{
                color: "#344054",
                borderRadius: "5px",
                fontFamily: theme.typography.fontFamily,
                fontSize: "12px",
                fontWeight: "600",
                background: "white",
              }}
            >
              {rows.length} Patients
            </div>
          </div>

          <TextField
            size="small"
            placeholder={`🔍 Search`}
            value={searchlog}
            onChange={handleSearchLog}
          />
        </Box>
        <Box>
          <CallLogsTable
            rows={callData}
            columns={callHeaders}
            searchlog={searchlog}
          />
        </Box>
      </Box>
    </>
  );
};

export default HomeScreen;
