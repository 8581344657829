import { CloseOutlined } from "@mui/icons-material";
import { Box, Divider, Drawer, Stack, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import BlankCommonBotton from "../../components/BlankCommonButton";
import pdfImg from "../../assets/pdf.png";
import zipImg from "../../assets/zip.png";
import docImg from "../../assets/doc.png";
import ReactToPrint from "react-to-print";
import CommonBotton from "../../components/CommonBotton";
import CallTable from "../../components/CallTable";
import theme from "../../layout/theme";

const RightPopWindow = ({ isOpen, toggleChat, OppUser, closeChat }) => {
  const printRef = useRef();

  const [files] = useState([
    {
      lastModified: 1712669111945,
      name: "Document(1).docx",
      size: 0,
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    },
    {
      lastModified: 1712669111945,
      name: "Document(1).pdf",
      size: 0,
      type: "pdf",
    },
    {
      lastModified: 1712669111945,
      name: "Document(1).zip",
      size: 0,
      type: "zip",
    },
  ]);

  const style = {
    root: {
      flexGrow: 1,
      padding: "20px",
    },
    fileItem: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "10px",
      border: "solid 1px #EAECF0",
      borderRadius: "10px",
      padding: "5px ",
    },
    fileIcon: {
      marginRight: "10px",
    },
  };

  function maskICNumber(icNumber) {
    if (icNumber.length <= 2) {
      return icNumber;
    }
    const firstChar = icNumber.charAt(0);
    const lastChar = icNumber.slice(-4);
    let maskedPart = "X".repeat(Math.max(0, icNumber.length - 5));
    return `${firstChar}${maskedPart}${lastChar}`;
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={toggleChat}>
      <Box
        id="printableContent"
        ref={printRef}
        px={1}
        position="relative"
        width={{
          xs: "400px",
          sm: "500px",
        }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        mx="auto"
        sx={{ mb: 10 }}
      >
        <div className="text-end">
          <CloseOutlined onClick={closeChat} className="hover" />
        </div>
        <div className="mb-3">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box>
                <Typography variant="h6" textAlign="left" sx={{  fontFamily: theme.typography.fontFamily,}}>
                  {OppUser ? OppUser.name : "Anthony Miller"}
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    display: "flex",
                    fontFamily: theme.typography.fontFamily,
                  }}
                >
                  {OppUser.id === 1 ? "Age 40" : " Age 51"}
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ borderColor: "black", mx: 0.5, my: 0.1 }}
                  />
                  {OppUser.gender}
                  <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ borderColor: "black", mx: 0.5, my: 0.1 }}
                  />{" "}
                  {OppUser ? maskICNumber(OppUser.ic_number) : "  E13254798R"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </div>
        <div className="">
          <h6
            className="mb-0 "
            style={{
              fontFamily: theme.typography.fontFamily,
              fontSize: "14px",
              fontWeight: "501",
              lineHeight: "20px",
              textAlign: "left",
            }}
          >
            Clinical Query
          </h6>
          <p
            className="mb-0 "
            style={{
              fontFamily: theme.typography.fontFamily,
              fontSize: "13px",
              fontWeight: "400",
              // lineHeight: "20px",
              // textAlign: "left",
            }}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis
            voluptatibus soluta corporis perspiciatis odit ipsam, distinctio at
            aperiam harum adipisci enim veniam reprehenderit cum ea officia? In
            error delectus dicta.
          </p>
        </div>
        <hr />
        <div className="my-3">
          <h6
            className="mb-0"
            style={{
              fontFamily: theme.typography.fontFamily,
              fontSize: "14px",
              fontWeight: "501",
              lineHeight: "20px",
              textAlign: "left",
            }}
          >
            Patient's History
          </h6>
          <p
            className="mb-0 "
            style={{
              fontFamily: theme.typography.fontFamily,
              fontSize: "13px",
              fontWeight: "400",
              // lineHeight: "20px",
              // textAlign: "left",
            }}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis
            voluptatibus soluta corporis perspiciatis odit ipsam, distinctio at
            aperiam harum adipisci enim veniam reprehenderit cum ea officia? In
            error delectus dicta.
          </p>
        </div>
        <hr />
        <div className="">
          <h6
            className="mb-0 "
            style={{
              fontFamily: theme.typography.fontFamily,
              fontSize: "14px",
              fontWeight: "501",
              lineHeight: "20px",
              textAlign: "left",
            }}
          >
            Documents
          </h6>
          <p
            className="mb-0 "
            style={{
              fontFamily: theme.typography.fontFamily,
              fontSize: "13px",
              fontWeight: "400",
            }}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis
            voluptatibus soluta corporis perspiciatis odit ipsam, distinctio at
            aperiam harum adipisci enim veniam reprehenderit cum ea officia? In
            error delectus dicta.
          </p>

          <Box m={3} mx={1}>
            <div className="d-flex flex-column align-items-center justify-content-between gap-3">
              {files.map((file, index) => (
                <div key={index} style={style.fileItem} className="col w-100">
                  <Box display="flex">
                    <Typography style={style.fileIcon}>
                      {
                        file.type.includes("image") ? (
                          <img src={zipImg} width={35} alt="file" />
                        ) : // "🖼️"
                        file.type.includes("video") ? (
                          <img src={zipImg} width={35} alt="file" />
                        ) : // "📹"
                        file.type.includes("pdf") ? (
                          <img src={pdfImg} width={35} alt="file" />
                        ) : // "📄"
                        file.type.includes("doc") ? (
                          <img src={docImg} width={35} alt="file" />
                        ) : (
                          // "📝"
                          <img src={zipImg} width={35} alt="file" />
                        )
                        // "📂"
                      }
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: theme.typography.fontFamily,
                        fontSize: "13px",
                        fontWeight: "500",
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#344054",
                        padding: "0 20px 0 0",
                      }}
                    >
                      {file.name}
                    </Typography>
                  </Box>
                </div>
              ))}
            </div>
            {/* </Box> */}
          </Box>
        </div>
        <hr />

        <CallTable />
      </Box>
      <Box
        sx={{
          bgcolor: "white",
          width: {
            xs: "400px",
            sm: "500px",
          },
          borderTop: "solid 1px #e9e7e7",
          position: "fixed",
          bottom: 0,
          zIndex: 1,
          pb: 1,
          pt: 1,
        }}
      >
        <Stack direction="row" spacing={1} justifyContent="end" px={2}>
          <BlankCommonBotton text="Close" onClick={closeChat} />
          <ReactToPrint
            trigger={() => (
              <CommonBotton
                text="Print a Copy"
                width="150px"
                type="submit"
                onClick={() => window.print()}
              />
            )}
            content={() => printRef.current}
          />
        </Stack>
      </Box>
    </Drawer>
  );
};

export default RightPopWindow;
