import { Button  } from "@mui/material";
import React from "react";
import { useTheme } from "styled-components"; 
import ButtonLoader from "./loader/ButtonLoader";

// const btnProps = {
//   text: "Continue",
//   color: "#fff",
//   Bgcolor: theme.palette.primary.main,
//   border: "#e98f1a",
//   bghover: "#e98f1a",
// };
const CommonBotton = ({width , text, icon, onClick , disabled , loading}) => {
  const theme = useTheme(); 
  return (
    <Button
      type="submit"
      variant="outlined"
      startIcon={icon}
      sx={{
        width: width,
        // margin: "10px 0px",
        backgroundColor: theme.palette.primary.main,
        color: "#fff !important",
        border: `1px solid  #e98f1a `,
        borderRadius:'8px',
        textTransform: "capitalize",
        "&:hover": {
          backgroundColor: "#e98f1a",
          border: `1px solid #e98f1a`,
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {loading === true ? <ButtonLoader /> :text}
    </Button>
  );
};

export default CommonBotton;
