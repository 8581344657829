import React, { useEffect } from 'react';

const JitsiMeeting = ({ roomName, onMeetingEnd }) => {
    useEffect(() => {
        // Ensure the Jitsi API is available
        if (window.JitsiMeetExternalAPI) {
            const domain = 'meet.jit.si';
            const options = {
                roomName: roomName,
                width: 800,
                height: 600,
                parentNode: document.querySelector('#jitsi-container'),
            };

            const api = new window.JitsiMeetExternalAPI(domain, options); // Use window object
            let callStartTime;
            let callDuration;
            api.isP2pActive().then(isP2p => {
                console.log(isP2p ,"isP2p")
            });
            api.addEventListener('videoConferenceJoined', () => {
                callStartTime = new Date();
                console.log('Call started at:', callStartTime);
            });

            api.addEventListener('videoConferenceLeft', () => {
                const callEndTime = new Date();
                callDuration = (callEndTime - callStartTime) / 1000; // Duration in seconds
                console.log('Call ended at:', callEndTime);
                console.log('Call duration (in seconds):', callDuration);
                onMeetingEnd();
            });

            return () => {
                api.dispose(); // Clean up Jitsi API instance
            };
        } else {
            console.error('JitsiMeetExternalAPI is not defined. Make sure the script is loaded.');
        }
    }, [roomName, onMeetingEnd]);

    return <div id="jitsi-container" style={{ width: '800px', height: '600px' }} />;
};

export default JitsiMeeting;
