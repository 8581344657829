import React from "react";
import { 
  Box, 
} from "@mui/material"; 
 
const SideCallDetail = () => {
 
  return (
    <div>
        <Box
        my={2}
        sx={{
          border: "solid 1px #ccc",
          borderRadius: "10px",
        }}
      >
        <Box
          textAlign="start"
          sx={{
            backgroundColor: "#F9FAFB",
            borderBottom: "1px solid #e5e4e4",
            borderRadius: "10px 10px 0px 0px ",
            padding: "5px",
            color: "#989696",
            display: "flex",
            alignItems: "center",
          }}
        >
          Call info
        </Box>
        <Box py={2} mx={0} textAlign='start' px={2} sx={{
            borderBottom: "1px solid #e5e4e4",
        }}>
        <p className="mb-2">Call date</p>
            <p className="mb-0" >30 Dec 2023</p>
           
        </Box>
        <Box py={2} mx={0} textAlign='start' px={2} sx={{
            borderBottom: "1px solid #e5e4e4",
        }}>
        <p className="mb-2">Call duration</p>
            <p className="mb-0" >13 min 41 sec</p>
           
        </Box>
        <Box py={2} mx={0} textAlign='start' px={2} sx={{
            // borderBottom: "1px solid #e5e4e4",
        }}>
        <p className="mb-2">Encounter</p>
            <p className="mb-0" >8:28PM to 8:41PM</p>
           
        </Box>
      </Box>
    </div>
  )
}

export default SideCallDetail