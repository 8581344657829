import { Button } from "@mui/material";
import React from "react";

const BlankCommonBotton = ({ image , width, text ,icon , onClick }) => {
  return (
    <Button
      type="submit"
      variant="outlined"
      startIcon={icon}
      sx={{
        width: width,
        margin: "10px 0px",
        backgroundColor: "#f3f3f3",
        color: "black",
        border: "1px solid #878786",
        borderRadius:'8px',
        textTransform: "capitalize",
        verticalAlign: "middle",
        "&:hover": {
          backgroundColor: "transparent",
          border: "1px solid #8787869e",
        },
      }}
      onClick={onClick}
    >
      {image ? <img src={image} alt="" className="object-fit-contain pe-2" width={50} /> : null}{text}
    </Button>
  );
};

export default BlankCommonBotton;
