import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CommonBotton from "../../components/CommonBotton";
import KeyIcon from "@mui/icons-material/Key";
import PersonIcon from "@mui/icons-material/Person";
import StepperList from "react-stepper-horizontal";
import BlankCommonBotton from "../../components/BlankCommonButton";
import theme from "../../layout/theme";
import logo from "../../assets/logo.png";
import {
  ArrowBack,
  ArrowBackIos,
  CheckCircle,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import singPassLogo from "../../assets/image 6.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid offWhite",
  boxShadow: 24,
  borderRadius: "15px",
  p: 4,
  textAlign: "center",
};
const SignUpNew = () => {
  const navigate = useNavigate();
  const LoginNavigate = () => {
    navigate("/login");
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    register: registers,
    handleSubmit: handleSubmits,
    setValue,
    formState: { errors: errorss },
  } = useForm();

  const submit = (data) => {
    // console.log(data);
  };

  const submits = (data) => {
    // console.log(data);
  };
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const handleChange = (pass) => {
    // var pass = event.target.value;
    if (pass.length > 0) {
      // var reg = /^[A-Z]*$/;
      var reg = /^(?=.*?[#?!@$%^&*-]).{8,}$/;
      var test = reg.test(pass);
      if (pass.length > 8) {
        setCheck1(true);
      } else {
        setCheck1(false);
      }
      if (test === true) {
        setCheck2(true);
      } else {
        setCheck2(false);
      }
    }
  };

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <KeyIcon />,
      2: <PersonIcon />,
    };

    return (
      <div ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </div>
    );
  }

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //SingPass

  const [unifidId, setunifidId] = useState(false);
  const [signPassres, setsignPassres] = useState();

  const {
    register: registerSingPass,
    handleSubmit: handleSubmitSingPass,
    formState: { errors: errorsSingPass },
  } = useForm();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [aliasName, setAliasName] = useState("");
  const submitSingPass = async (data) => {
    // console.log(data.unifinId);

    try {
      const res = await axios.get(
        `https://sandbox.api.myinfo.gov.sg/com/v4/person-sample/${data.unifinId}`
      );
      if (res.status === 200) {
        await setsignPassres(res.data);
        const { name, saluation, employmentsector } = res.data;
        // console.log(employmentsector);
        setValue("aliasname", name.value);
        setAliasName(name.value);
        handleClose();
        // console.log(res.data, "data.unifinId");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(signPassres);
  return (
    <div>
      {activeStep === 0 ? (
        <Box
          sx={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              padding: "12px",
              border: `solid 1px ${theme.palette.primary.main}`,
              borderRadius: "100%",
              boxShadow: `${theme.palette.primary.main} 0px 0px 0px 0.1px inset , rgb(255, 255, 255) 0px 0px 0px 31px , ${theme.palette.primary.main} 0px 0px 0px 31px, rgb(255, 255, 255) 0px 0px 0px 61px , ${theme.palette.primary.main} 0px 0px 0px 61px , rgb(255, 255, 255) 0px 0px 0px 91px , ${theme.palette.primary.main} 0px 0px 0px 91px , rgb(255, 255, 255) 0px 0px 0px 121px , ${theme.palette.primary.main} 0px 0px 0px 121px , rgb(255, 255, 255) 0px 0px 0px 150px , ${theme.palette.primary.main} 0px 0px 0px 150px`,
            }}
          >
            <img src={logo} alt="" width={50} />
          </Box>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: "600",
              fontSize: "30px",
              lineHeight: "38px",
              fontFamily: theme.typography.fontFamily,
            }}
          >
            Create an account
          </Typography>
          <form
            style={{ width: "350px" }}
            action=""
            id="signupform"
            onSubmit={handleSubmit(submit)}
            noValidate
          >
            <Box>
              <Stack
                spacing={2}
                sx={{
                  width: "100%",
                }}
                // width={{
                //   xs: 100,
                //   sm: 300,
                //   md: 350,
                // }}
              >
                <div className="text-start">
                  <InputLabel
                    shrink
                    htmlFor="email"
                    sx={{
                      fontWeight: 501,
                    }}
                  >
                    Email*
                  </InputLabel>
                  <TextField
                    id="email"
                    size="small"
                    sx={{
                      backgroundColor: "white",
                    }}
                    fullWidth
                    placeholder="Enter your email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@(gmail|yahoo)\.com$/i,
                        message: "Please enter a valid  email address",
                      },
                    })}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    FormHelperTextProps={{ style: { margin: 0 } }}
                  />
                </div>

                <div className="text-start">
                  <InputLabel
                    shrink
                    htmlFor="pass"
                    sx={{
                      fontWeight: "501",
                    }}
                  >
                    Password*
                  </InputLabel>
                  <TextField
                    size="small"
                    fullWidth
                    id="pass"
                    sx={{
                      backgroundColor: "white",
                    }}
                    placeholder="Create a password"
                    type={showPassword ? "text" : "password"}
                    // onChange={(e) => handleChange(e.target.value)}
                    {...register("password", {
                      required: "Password is required",
                      onChange: (e) => handleChange(e.target.value),
                    })}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                    FormHelperTextProps={{ style: { margin: 0 } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handlePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <Stack>
                  <Typography
                    fontSize={13}
                    textAlign="start"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                    }}
                  >
                    <Checkbox
                      id="check1"
                      color="primary"
                      checked={check1}
                      icon={<CheckCircle />}
                      checkedIcon={<CheckCircle />}
                      disabled={!check1}
                    />{" "}
                    Must be at least 8 characters
                  </Typography>
                  <Typography fontSize={13} textAlign="start" sx={{  fontFamily: theme.typography.fontFamily,}}>
                    <Checkbox
                      id="check2"
                      color="primary"
                      checked={check2}
                      icon={<CheckCircle />}
                      checkedIcon={<CheckCircle />}
                      disabled={!check2}
                    />{" "}
                    Must contain one special character
                  </Typography>
                </Stack>
                <CommonBotton
                  text="Continue"
                  type="submit"
                  form="signupform"
                  onClick={handleNext}
                />
              </Stack>
            </Box>
          </form>

          <Typography
            variant="p"
            sx={{
              fontSize: "12px",
              margin: "20px auto !important",
              fontFamily: theme.typography.fontFamily,
            }}
          >
            Already have an account ?{" "}
            <Typography
              variant="span"
              sx={{
                color: theme.palette.primary.main,
                fontFamily: theme.typography.fontFamily,
                fontWeight: "600",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => LoginNavigate()}
            >
              Log in
            </Typography>
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            height: "80vh",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              padding: "12px",
              border: `solid 1px ${theme.palette.primary.main}`,
              borderRadius: "100%",
              boxShadow: `${theme.palette.primary.main} 0px 0px 0px 0.1px inset , rgb(255, 255, 255) 0px 0px 0px 31px , ${theme.palette.primary.main} 0px 0px 0px 31px, rgb(255, 255, 255) 0px 0px 0px 61px , ${theme.palette.primary.main} 0px 0px 0px 61px , rgb(255, 255, 255) 0px 0px 0px 91px , ${theme.palette.primary.main} 0px 0px 0px 91px , rgb(255, 255, 255) 0px 0px 0px 121px , ${theme.palette.primary.main} 0px 0px 0px 121px , rgb(255, 255, 255) 0px 0px 0px 150px , ${theme.palette.primary.main} 0px 0px 0px 150px`,
            }}
          >
            <img src={logo} alt="" width={50} />
          </Box>
          <Box textAlign="start" width="350px" my={2}>
            <Typography
              variant="p"
              startIcon={<ArrowBackIos />}
              onClick={handleBack}
              sx={{
                fontFamily: theme.typography.fontFamily,
                fontSize: "15px",
                color: "#475467",
                verticalAlign: "baseline",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <ArrowBackIos sx={{ fontSize: "12px", color: "#475467" }} />
              Back
            </Typography>
          </Box>
          <form
            action=""
            onSubmit={handleSubmits(submits)}
            className="py-2"
            style={{ width: "350px", background: "white" }}
            id="createForm"
          >
            <Stack direction="row" gap={4} px={2}>
              <div className="text-start">
                <InputLabel shrink htmlFor="saluation">
                  Saluation*
                </InputLabel>
                <TextField
                  size="small"
                  id="saluation"
                  // label="Saluation*"
                  variant="outlined"
                  {...registers("saluation", {
                    required: "Saluation is required",
                  })}
                  error={!!errorss.saluation}
                  helperText={errorss.saluation?.message}
                  FormHelperTextProps={{ style: { margin: 0 } }}
                />
              </div>

              <div className="text-start">
                <InputLabel shrink htmlFor="aliasName">
                  Name*
                </InputLabel>
                <TextField
                  size="small"
                  id="aliasName"
                  variant="outlined"
                  value={aliasName}
                  {...register("aliasname", {
                    required: "Name is required",
                    onChange: (e) => setAliasName(e.target.value),
                    // Set defaultValue to aliasNameDefaultValue
                    // defaultValue: signPassres.aliasname || "",
                  })}
                  error={!!errorss.aliasname}
                  helperText={errorss.aliasname?.message}
                  FormHelperTextProps={{ style: { margin: 0 } }}
                />
              </div>
            </Stack>
            <Stack gap={2} mt={3} mb={1} px={2}>
              <div className="w-100 text-start">
                <InputLabel shrink htmlFor="medicalLno">
                  Medical License no.*
                </InputLabel>
                <TextField
                  id="medicalLno."
                  size="small"
                  variant="outlined"
                  fullWidth
                  {...registers("medical_licence", {
                    required: "Medical Licence is required",
                  })}
                  error={!!errorss.medical_licence}
                  helperText={errorss.medical_licence?.message}
                  FormHelperTextProps={{ style: { margin: 0 } }}
                />
              </div>

              <div className="text-start">
                <InputLabel shrink htmlFor="clinicN">
                  Clinic Name*
                </InputLabel>
                <TextField
                  id="clinicN"
                  size="small"
                  variant="outlined"
                  fullWidth
                  {...registers("clinic_name", {
                    required: "Clinic Name is required",
                  })}
                  error={!!errorss.clinic_name}
                  helperText={errorss.clinic_name?.message}
                  FormHelperTextProps={{ style: { margin: 0 } }}
                />
              </div>
            </Stack>

            <Divider
              textAlign="center"
              sx={{
                border: "1px solid #E6E6E",
                height: "40px",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  fontSize: "15px",
                  fontFamily: theme.typography.fontFamily,
                }}
              >
                OR
              </Typography>
            </Divider>
            <BlankCommonBotton
              image={singPassLogo}
              text="Retrieve info from SingPass"
              width="100%"
              onClick={() => handleOpen()}
            />
            <CommonBotton
              text="Continue"
              form="createForm"
              type="submit"
              width="100%"
            />
          </form>
        </Box>
      )}
      <Box
        mx="auto"
        width={{
          xs: "100%",
          sm: "100%",
          md: "600px",
        }}
      >
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel
              icon={
                <KeyIcon
                  sx={{
                    padding: " 5px",
                    border: "#5aff5a42",
                    borderRadius: "5px",
                    backgroundColor: "#5aff5a42",
                  }}
                />
              }
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              Set up a new account <br />
              <Typography variant="span" sx={{ color: "#08e488" ,  fontFamily: theme.typography.fontFamily, }}>
                {!activeStep ? "create your credentials" : "Completed"}
              </Typography>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel
              icon={
                <PersonIcon
                  sx={{
                    padding: " 5px",
                    border: "#a0baa042",
                    borderRadius: "5px",
                    backgroundColor: "#a0b1a042",
                  }}
                />
              }
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              Set up a new account <br />
              create your credentials
            </StepLabel>
          </Step>
        </Stepper>
        {activeStep === 0 ? (
          <div>
            {/* Step 1 Content */}
            {/* <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button> */}
          </div>
        ) : (
          <div>
            {/* Step 2 Content */}
            {/* <Button variant="contained" onClick={handleBack}>
              Back
            </Button> */}
          </div>
        )}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="">
            <form
              action=""
              id="singPassForm"
              onSubmit={handleSubmitSingPass(submitSingPass)}
              className="text-start"
            >
              <Typography my={2} sx={{  fontFamily: theme.typography.fontFamily,}}>Please enter your UNIFIN </Typography>
              <TextField
                id="standard-basic"
                label="UNIFIN Id*"
                variant="outlined"
                fullWidth
                size="small"
                {...registerSingPass("unifinId", {
                  required: "UNIFIN id is required",
                })}
                error={!!errorsSingPass.unifinId}
                helperText={errorsSingPass.unifinId?.message}
              />
              <br />
              <Box textAlign="right">
                <CommonBotton text="submit" type="submit" form="singPassForm" />
              </Box>
            </form>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SignUpNew;
