import React from "react";
import "./fileTabs.css"; // Import CSS file
import {
  Description,
  Image,
  InsertChartOutlined,
  InsertDriveFile,
  InsertDriveFileOutlined,
  PictureAsPdf,
} from "@mui/icons-material";

const FileTab = ({ link, fileName }) => {
  // Extract file type from link
  const fileType = fileName.split(".").pop().toUpperCase();

  // Determine icon class based on file type
  const iconMap = {
    XML: <InsertChartOutlined />,
    JPG: <Image />,
    PDF: <PictureAsPdf />,
    DOCX: <Description />,
    PPTX: <InsertDriveFile />,
  };

  const iconComponent = iconMap[fileType] || <InsertDriveFileOutlined />;

  return (
    <div className="file-tab">
      <div className="file-icon">{iconComponent}</div>
      <div className="file-info">
        <a
          href={link}
          className="file-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="file-name">{fileName}</div>
        </a>
      </div>
    </div>
  );
};

export default FileTab;
