import { JitsiMeeting } from "@jitsi/react-sdk";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import "./jitsi.css";

const Jitsi = ({ height, width, onMeetingEnd , name }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const name = queryParams.get("name");

  const [roomName, setRoomName] = useState("HiveMeetingRoom");
  const jitsiContainerRef = useRef(null);
  const [api, setApi] = useState(null);

  const handleMeetingEnd = () => {
    if (onMeetingEnd) {
      onMeetingEnd();
    }
  };

  const handlePictureInPicture = async (videoElement) => {
    if (videoElement && document.pictureInPictureEnabled) {
      try {
        await videoElement.requestPictureInPicture();
      } catch (error) {
        console.error("Error requesting Picture-in-Picture:", error);
      }
    }
  };

  useEffect(() => {
    if (api) {
      const conferenceJoinedListener = () => {
        const videoElement = document.querySelector("video");
        handlePictureInPicture(videoElement);
      };

      api.addEventListener("videoConferenceJoined", conferenceJoinedListener);

      // Cleanup listener on component unmount
      return () => {
        api.removeEventListener(
          "videoConferenceJoined",
          conferenceJoinedListener
        );
      };
    }
  }, [api]);

  return (
    <div className="jitsi-container">
      <JitsiMeeting
        domain="meet.jit.si"
        roomName={roomName}
        configOverwrite={{
          startWithAudioMuted: true,
          hiddenPremeetingButtons: ["microphone"],
          disableModeratorIndicator: true,
          startScreenSharing: true,
          enableEmailInStats: false,
          defaultLogoUrl: "", // Usually not needed if interfaceConfig is set
        }}
        interfaceConfigOverwrite={{
          TOOLBAR_BUTTONS: [
            "microphone",
            "camera",
            "closedcaptions",
            "desktop",
            "fullscreen",
            "fodeviceselection",
            "hangup",
            "profile",
            "chat",
            "recording",
            "livestreaming",
            "etherpad",
            "sharedvideo",
            "settings",
            "raisehand",
            "videoquality",
            "filmstrip",
            "invite",
            "feedback",
            "stats",
            "shortcuts",
            "tileview",
            "videobackgroundblur",
            "download",
            "help",
            "mute-everyone",
          ],
          CLOSE_PAGE_GUEST_HINT: false,
          BRAND_WATERMARK_LINK: "",
          SHOW_JITSI_WATERMARK: false,
          HIDE_DEEP_LINKING_LOGO: true,
          SHOW_BRAND_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          SHOW_POWERED_BY: false,
          SHOW_PROMOTIONAL_CLOSE_PAGE: false,
        }}
        userInfo={{
          displayName: name,
        }}
        onApiReady={(externalApi) => {
          setApi(externalApi);
          externalApi.addEventListener("readyToClose", handleMeetingEnd);
          externalApi.addEventListener("videoConferenceJoined", () => {
            const videoElement = document.querySelector("video");
            handlePictureInPicture(videoElement);
          });
        }}
        getIFrameRef={(iframe) => {
          if (iframe) {
            iframe.style.height = height ? height : '100vh';
            iframe.style.overflow = "hidden";
          }
        }}
      />
    </div>
  );
};

export default Jitsi;
