import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Avatar,
  Typography,
} from "@mui/material";
import theme from "../layout/theme";

const CallLogsTable = ({ rows, columns, searchlog }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = rows
    .filter((row) => {
      const matchesSearchQuery = Object.values(row).some((value) =>
        value.toString().toLowerCase().includes(searchlog.toLowerCase())
      );
      return matchesSearchQuery;
    })
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const paginatedRows = filteredRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Paper
      sx={{
        boxShadow: "none",
        borderRadius: "10px",
      }}
    >
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              backgroundColor: "#F9FAFB",
            }}
          >
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.id === "name" ? ( // Check if column is 'name'
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            src={row[column.id]}
                            alt={row[column.id]}
                            style={{ width: 30, height: 30, marginRight: 10 }}
                          />
                          {row[column.id]}
                        </div>
                      ) : (
                        // Render text for other columns
                        row[column.id]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {paginatedRows.length > 0 ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            ".css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar > p": {
              margin: "0 !important",
            },
          }}
        />
      ) : (
        <Typography my={3} sx={{  fontFamily: theme.typography.fontFamily,}}>No Data Found</Typography>
      )}
    </Paper>
  );
};

export default CallLogsTable;
