import React, { useState } from "react";
import LayoutSidebar from "../../layout/Layout";
import Basictabs from "../../components/Basictabs";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  Typography,
  Paper,
} from "@mui/material";
import ChatWindow from "../chat/ChatWindow";
import PropTypes from "prop-types";
import CallLogScreen from "../CallLogs/CallLogScreen";
import CallLogPreview from "../CallLogs/CallLogPreview";
import CallLogP2 from "../CallLogs/CallLogP2";
import DragAndDrop from "../../components/DragAndDrop";
import TextFieldGmail from "../../components/TextFieldGmail";
import QueryScreen from "../QueryScreen";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import theme from "../../layout/theme";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography variant="span" sx={{  fontFamily: theme.typography.fontFamily,}} >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NewCall = () => {
  const no = localStorage.getItem("screenNumber");
  const tab = localStorage.getItem("screenTab");
  const [value, setValue] = React.useState(tab ? parseInt(tab) : 0);

  const [screenNumber, setScreenNumber] = useState(no ? parseInt(no) : 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("screenTab", newValue);
  };

  const handleDataFromChildfiles = (data) => {
    // setfiles(data);
    // console.log(data);
  };

  const screenChange = (data) => {
    // console.log(`Changing screen to: ${data}`);
    localStorage.setItem("screenNumber", data);
    setScreenNumber(data);
  };

  const handleDataFromChild = (data) => {
    // console.log(data);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            p={2}
            sx={{
              display: "flex",
            }}
          >
            <Avatar alt="A" />
            <Box pl={2}>
              <Typography
                variant="h5"
                textAlign="left"
                sx={{
                  fontSize: "30px",
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: "600",
                  lineHeight: "38px",
                  textAlign: "left",
                }}
              >
                Anthony Miller
              </Typography>
              <Typography
                variant="span"
                sx={{
                  display: "flex",
                  fontSize: "14px",
                  fontFamily: theme.typography.fontFamily,
                  fontWeight: "400",
                  lineHeight: "20px",
                  textAlign: "left",
                  color: "#98A2B3",
                }}
              >
                {" "}
                Age 51 | Male | SXXXXX933H
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                color: "#98A2B3",
                fontFamily: theme.typography.fontFamily,
              }}
            >
              last clinic vist on 19 December 2023
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* =================== Tabs 3  ======================*/}
      <Box>
        {/* <Basictabs /> */}
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="New consult" sx={{textTransform:'capitalize'}} {...a11yProps(0)} />
              <Tab label="Patient records" sx={{textTransform:'capitalize'}}  {...a11yProps(1)} />
              <Tab label="Call Logs" sx={{textTransform:'capitalize'}} {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <QueryScreen />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Typography
              variant="h4"
              sx={{
                fontSize: "18px",
                fontFamily: theme.typography.fontFamily,
                fontWeight: "600",
                lineHeight: "28px",
                textAlign: "left",
              }}
            >
              Clinical Query
            </Typography>
            <Typography textAlign="left" sx={{ textAlign: "start" ,  fontFamily: theme.typography.fontFamily, }} my="10px">
              Enter critical information required to better understand the
              medical concern
            </Typography>
            <Box my={2}>
              <TextFieldGmail height="300px" onData={handleDataFromChild} />
            </Box>
            <DragAndDrop onDataFiles={handleDataFromChildfiles} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            {screenNumber === 0 ? (
              <CallLogScreen onScreenChange={screenChange} />
            ) : null}
            {screenNumber === 1 ? (
              <CallLogPreview onScreenChange={screenChange} />
            ) : null}
            {screenNumber === 2 ? (
              <CallLogP2 onScreenChange={screenChange} />
            ) : null}
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default NewCall;
