import { Avatar, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import PreviewModal from "./PreviewModal";
import FileTab from "./FileTabs";
import { PlayCircle } from "@mui/icons-material";
import Moment from "react-moment";
import theme from "../../../layout/theme";

const Message = ({ messages, myData, mId, OppUser }) => {
  const messageDate = new Date(messages.created_at);
  const today = new Date();

  const isToday = messageDate.toDateString() === today.toDateString();

  //MOdal

  const [showModal, setShowModal] = useState(false);
  const [previewContent, setPreviewContent] = useState({});

  const openModal = (type, url) => {
    setPreviewContent({ type, url });
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      {/* <messagesScreen /> */}
      <Box
        p={1}
        sx={{
          display: "flex",
          justifyContent:
            myData.id === messages.sender_id ? "flex-end" : "flex-start",
          // alignItems: "center",
        }}
        key={mId}
      >
        
        {myData.id !== messages.sender_id ? (
          <Avatar
              alt="Recepient Profile Pic"
              sx={{ width: 30, height: 30 }}
              src={OppUser.profilePicture}
            />
          ) : null}
        <Box
          sx={{
            textAlign: myData.id === messages.sender_id ? "right" : "left",
            ml:
              myData.id === messages.sender_id
                ? { sm: 1, xs: 1 }
                : { sm: 1, xs: 1 },
            mr:
              myData.id === messages.sender_id
                ? { sm: 1, xs: 1 }
                : { sm: 1, xs: 1 },
            minWidth: "150px",
          }}
        >
          <Typography
            px={1}
            sx={{
              display: "block",
              textAlign: myData.id === messages.sender_id ? "right" : "left",
              fontSize: 11,
              fontFamily: theme.typography.fontFamily,
              color: "gray",
              display: "flex",
              justifyContent: "space-between",
              gap:2
            }}
            variant="caption"
          >
            {myData.id === messages.sender_id ? "You" : OppUser.name}

            {isToday ? (
              <span>
                Today <Moment format="h:mm a">{messages.created_at}</Moment>
              </span>
            ) : (
              <Moment format="MMMM D, YYYY h:mm a">
                {messages.created_at}  
              </Moment>
            )}

            {/* {moment(messages.created_at).fromNow()} */}
          </Typography>
          
          <Typography
            variant="subtitle1"
            sx={{
              fontFamily: theme.typography.fontFamily,
              border: "none",
              borderColor: "lightgrey",
              bgcolor: myData.id === messages.sender_id ? "#FEC84B" : "#F2F4F7",
              borderTopRightRadius: myData.id === messages.sender_id ? -0 : 8,
              borderTopLeftRadius: myData.id === messages.sender_id ? 8 : 0,
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
              p: 1,
              mt: 1,
              ml:
                myData.id === messages.sender_id
                  ? { sm: 1, xs: 1 }
                  : { sm: 0, xs: 0 },
              mr:
                myData.id === messages.sender_id
                  ? { sm: 1, xs: 1 }
                  : { sm: 0, xs: 0 },
              display: "inline-block",
              wordBreak: "break-word",
            }}
          >
            {messages.type === "text" ? (
              messages.message
            ) : messages.type === "img" ? (
              <img
                src={messages.message}
                alt=""
                className="object-fit-contain"
                width={200}
                height={200}
                onClick={() => openModal("image", messages.message)}
              />
            ) : messages.type === "vid" ? (
              <div className="position-relative">
                <video
                  src={messages.message}
                  // controls={true}
                  className="object-fit-contain"
                  width={200}
                  height={200}
                  onClick={() => openModal("video", messages.message)}
                />
                <PlayCircle
                  onClick={() => openModal("video", messages.message)}
                  fontSize="large"
                  color="warning"
                  className="position-absolute top-50 start-50 translate-middle"
                />
              </div>
            ) : (
              <FileTab link={messages.message} fileName={messages?.fileName} />
            )}
          </Typography>
        </Box>

        {/* {myData.id === messages.sender_id ? (
          <Avatar
            alt="Recepient Profile Pic"
            sx={{ width: 30, height: 30, mr: 2 }}
            src={myData.id === messages.sender_id ? myData.dp : OppUser.dp}
          />
        ) : null} */}
      </Box>

      <PreviewModal
        show={showModal}
        content={previewContent}
        onClose={closeModal}
      />

      {/* );
          })
        : null} */}
    </>
  );
};

export default Message;
