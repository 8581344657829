import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import Accordin from "../../components/Accordin";
import CallTable from "../../components/CallTable";
import RatingComponent from "../../components/RatingComponent";
import CommonBotton from "../../components/CommonBotton";
import {
  ArrowBack,
  ArrowForward,
  Download,
  DraftsOutlined,
} from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { saveAs } from "file-saver";
import theme from "../../layout/theme";

const CallLogPreview = ({ onScreenChange }) => {
  const {
    register,
    handleSubmit,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm();

  const [rating, setRating] = useState(3);

  const submit = (data) => {
    // console.log(data, rating);
    onScreenChange(2);
  };
  const images = [
    "",
    "https://images.sampleforms.com/wp-content/uploads/2020/04/Application-Form-for-Accreditation-of-Hospital.jpg",
    "https://via.placeholder.com/600/d32776",
    "https://via.placeholder.com/600/f66b97",
    // Add more image URLs as needed
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(1);

  const handleDownloadImage = async () => {
    const currentImage = images[currentImageIndex];
    try {
      const response = await fetch(currentImage, { responseType: "blob" });
      const blob = await response.blob();
      saveAs(blob, `image${currentImageIndex + 1}.jpg`);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };
  return (
    <Box className="">
      <Box my={2}>
        <Stack textAlign="start">
          <Typography
            variant="h6"
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontSize: "18px",
              fontWeight: "600",
              lineHeight: "28px",
              textAlign: "left",
            }}
          >
            Summary of consultation with Dr Benjamin
          </Typography>
          <Typography
            sx={{
              fontFamily: theme.typography.fontFamily,
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "20px",
              textAlign: "left",
            }}
          >
            Call logged on 30 Dec 2023
          </Typography>
        </Stack>
      </Box>
      <Box>
        <Accordin title="Clinical Query" />
      </Box>
      <Box my={2}>
        <Accordin title="Consult Summary" />
      </Box>
      <Box>
        <Accordin title="Documents" />
      </Box>

      <Box my={2}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={5.8}
            mr={{
              md: 2,
              xs: 0,
            }}
            mb={{
              xs: 2,
              md: 0,
            }}
            sx={{
              // background: "#f4f4f4",
              // borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            {/* <ImagePreview images={images} /> */}
            {/* <Preview images={images}/> */}
            <Box
              sx={{
                border: "solid 1px #ccc",
                borderRadius: "10px",
              }}
            >
              <Box
                textAlign="start"
                sx={{
                  backgroundColor: "#f0efef",
                  borderBottom: "1px solid #e5e4e4",
                  borderRadius: "10px 10px 0px 0px ",
                  padding: "5px",
                  color: "#989696",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "20px",
                    textAlign: "left",
                    color: "#344054",
                  }}
                >
                  Preview Referral Letter
                </Typography>
                <IconButton
                  variant="outlined"
                  color="primary"
                  onClick={handleDownloadImage}
                >
                  <Download />
                </IconButton>
              </Box>
              <div>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "0px",
                    height: "400px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={images[currentImageIndex]}
                    alt={`${currentImageIndex + 1}`}
                    style={{ maxWidth: "100%", maxHeight: "auto" }}
                  />
                </div>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  my={2}
                >
                  <Button
                    startIcon={<ArrowBack />}
                    disabled={currentImageIndex === 1}
                    onClick={() => setCurrentImageIndex(currentImageIndex - 1)}
                  ></Button>
                  <Typography sx={{  fontFamily: theme.typography.fontFamily,}}>Page {currentImageIndex} of 3</Typography>
                  <Button
                    endIcon={<ArrowForward />}
                    disabled={currentImageIndex === 3}
                    onClick={() => setCurrentImageIndex(currentImageIndex + 1)}
                  ></Button>
                </Box>
              </div>
            </Box>
          </Grid>
          <Grid item md={5.8} xs={12}>
            <Box
              sx={{
                background: "#f4f4f4",
                borderRadius: "10px",
              }}
            >
              <CallTable />
              <Grid container m={2}>
                <Grid item xs={4}>
                  <Stack spacing={2} textAlign="start">
                    <Typography sx={{  fontFamily: theme.typography.fontFamily,}}>Give a rating</Typography>
                    <Typography sx={{  fontFamily: theme.typography.fontFamily,}}>Any feedback</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={7}>
                  <Stack spacing={2} textAlign="start">
                    <RatingComponent
                      value={rating}
                      readOnly={false}
                      onChange={setRating}
                    />
                    <form onSubmit={handleSubmit(submit)}>
                      <textarea
                        name=""
                        placeholder="Type here... e.g. the audio is good"
                        id=""
                        cols="30"
                        rows="10"
                        style={{
                          background: "#fff",
                          border: "solid 1px #ccc",
                          borderRadius: "5px",
                          padding: "10px",
                          resize: "none",
                          width: "100%",
                        }}
                        {...register("feedback")}
                      ></textarea>
                      <Box textAlign="end">
                        <CommonBotton text="Submit" />
                      </Box>
                    </form>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                background: "#f4f4f4",
                borderRadius: "10px",
                padding: " 8px",
              }}
            >
              <DraftsOutlined /> Return to dashboard
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CallLogPreview;
