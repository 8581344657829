/* eslint-disable no-unused-vars */
// TableWithPagination.js

import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  Avatar,
  ButtonGroup,
} from "@mui/material";
import { TiTick } from "react-icons/ti";
import { PhonePaused } from "@mui/icons-material";
import theme from "../layout/theme";

const TableWithPagination = ({ rows, columns, searchData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset page when search query changes
  };

  const handleStatusFilterChange = (status) => {
    setStatusFilter(status);
    setPage(0);
  };

  let filteredRows = rows.filter((row) => {
    const matchesSearchQuery = Object.values(row).some((value) =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (statusFilter === "All") {
      return matchesSearchQuery;
    } else if (statusFilter === "recent") {
      const formatDate = (date) => {
        const options = { year: "numeric", month: "short", day: "numeric" };
        return date.toLocaleDateString("en-US", options);
      };

      // Get the current date formatted as "MMM DD, YYYY"
      const currentDate = formatDate(new Date());
      // Remove the time part for comparison
      const rowDate = formatDate(new Date(row.date));
      // console.log( currentDate >= rowDate ,matchesSearchQuery);

      return matchesSearchQuery;
    } else {
      return matchesSearchQuery && row.status === statusFilter;
    }
  });

  if (statusFilter === "recent") {
    filteredRows = filteredRows.sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
  }

  // console.log(filteredRows);

  const headers = columns.map((column) => ({
    label: column.label,
    key: column.id,
  }));
  const csvData = filteredRows.map((row) => Object.values(row));
  return (
    <Paper
      sx={{
        boxShadow: "none",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ButtonGroup
          variant="contained"
          aria-label="status filter"
          style={{
            margin: "1rem",
            boxShadow: "none",
          }}
        >
          <Button
            onClick={() =>
              handleStatusFilterChange(
                statusFilter === "recent" ? "All" : "recent"
              )
            }
            sx={
              statusFilter === "recent"
                ? {
                    border: "solid 1px gray",
                    backgroundColor: "transparent",
                    color: "orange",
                    textTransform: "capitalize",
                    boxShadow: "none", 

                    "&:hover": {
                      border: "solid 1px gray",
                      backgroundColor: "transparent",
                      color: "success",
                      boxShadow: "none",
                    },
                    "&:active": {
                      boxShadow: "none",
                      border: "solid 1px gray",
                    },
                  }
                : {
                    backgroundColor: "transparent",
                    border: "solid 1px gray",
                    color: "gray",
                    textTransform: "capitalize",
                    boxShadow: "none",

                    "&:hover": {
                      border: "solid 1px gray",
                      backgroundColor: "transparent",
                      color: "gray",
                    },
                    "&:active": {
                      boxShadow: "none",
                      border: "solid 1px gray",
                    },
                  }
            }
          >
            Recent Consults
          </Button>
          <Button
            onClick={() => handleStatusFilterChange("incomplete")}
            sx={
              statusFilter === "incomplete"
                ? {
                    border: "solid 1px gray",
                    backgroundColor: "transparent",
                    color: "orange",
                    textTransform: "capitalize",

                    "&:hover": {
                      border: "solid 1px gray",
                      backgroundColor: "transparent",
                      color: "success",
                      textTransform: "capitalize",
                    },
                    "&:active": {
                      boxShadow: "none",
                      border: "solid 1px gray",
                    },
                  }
                : {
                    backgroundColor: "transparent",
                    border: "solid 1px gray",
                    color: "gray",
                    textTransform: "capitalize",

                    "&:hover": {
                      border: "solid 1px gray",
                      backgroundColor: "transparent",
                      color: "gray",
                    },
                    "&:active": {
                      boxShadow: "none",
                      border: "solid 1px gray",
                    },
                  }
            }
            // color={statusFilter === "Active" ? "primary" : "success"}
          >
            Incomplete
          </Button>
        </ButtonGroup>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  {/* {columns.map((column) => (
                    <TableCell key={column.id}>{row[column.id]}</TableCell>
                  ))} */}

                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.id === "name" ? ( // Check if column is 'name'
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Avatar
                            src={row[column.id]}
                            alt={row[column.id]}
                            style={{ width: 30, height: 30, marginRight: 10 }}
                          />
                          {row[column.id]}
                        </div>
                      ) : column.id === "status" ? ( // Check if column is 'action'
                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={
                            row[column.id] === "Pending" ||
                            row[column.id] === "Referred" ? (
                              <TiTick />
                            ) : row[column.id] === "Call on Hold" ? (
                              <PhonePaused />
                            ) : null
                          }
                          sx={
                            row[column.id] === "Pending" ||
                            row[column.id] === "Referred"
                              ? {
                                fontFamily: theme.typography.fontFamily,
                                  border: "solid 1px #067647",
                                  color: "#067647",
                                  borderRadius: "30px",
                                  fontSize: "12px",
                                  padding: "5px 10px",
                                  "&:hover": {
                                    border: "solid 1px green",
                                    color: "green",
                                  },
                                }
                              : row[column.id] === "Call on Hold"
                              ? {
                                fontFamily: theme.typography.fontFamily,
                                  border: "solid 1px #DC6803",
                                  color: "#DC6803",
                                  borderRadius: "30px",
                                  fontSize: "12px",
                                  padding: "5px 10px",
                                  "&:hover": {
                                    border: "solid 1px #f09414",
                                    color: "#f09414",
                                  },
                                }
                              : {
                                fontFamily: theme.typography.fontFamily,
                                  border: "solid 1px #067647",
                                  color: "#067647",
                                  borderRadius: "30px",
                                  fontSize: "12px",
                                  padding: "5px 10px",
                                  "&:hover": {
                                    border: "solid 1px green",
                                    color: "green",
                                  },
                                }
                          }
                          // onClick={() => console.log("Button clicked")}
                        >
                          {row[column.id]}
                        </Button>
                      ) : (
                        // Render text for other columns
                        row[column.id]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          ".css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar > p": {
            margin: "0 !important",
          },
        }}
      />
    </Paper>
  );
};

export default TableWithPagination;
