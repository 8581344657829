// Sidebar.js
import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  IconButton,
  ListItemText,
  Avatar,
} from "@mui/material";
import {
  HomeOutlined as HomeOutlinedIcon,
  AccountBoxOutlined,
  LibraryAddCheckOutlined,
  CallSplitRounded,
  SettingsOutlined,
  AccountCircleOutlined,
  Logout,
  FormatListBulleted,
  PersonAddAlt,
} from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RxAvatar } from "react-icons/rx"; // Assuming you are using react-icons for RxAvatar
import logo from "../../assets/logo.png"; // Replace with your actual logo import
import { useAuth } from "../../middleware/authContext";
import Dp from "../../assets/Steve.png";
import { auth, db, doc, onSnapshot, signOut } from "../../FirebaseAuth";
import theme from "../../layout/theme";
const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hover, setHover] = useState(false);
  const isActive = (path) => location.pathname === path;

  const { currentUser } = useAuth();
  const [user, setUserData] = useState();
  const handleLogout = () => {
    // logout();
    localStorage.setItem("email", "");
    signOut(auth);
  };

  useEffect(() => {
    const getChats1 = () => {
      const unsub1 = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
        setUserData({ ...doc.data(), id: currentUser.uid });
      });

      return () => {
        unsub1();
      };
    };
    currentUser?.uid && getChats1();
  }, [currentUser?.uid]);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        width: 60,
        flexShrink: 0,
        overflow: "hidden",
        transition: "width 0.3s",
        "&:hover": {
          width: 250,
        },
        "& .MuiDrawer-paper": {
          width: 60,
          overflow: "hidden",
          backgroundColor: "#0038CD !important",
          boxSizing: "border-box",
          transition: "width 0.3s",
          "&:hover": {
            width: 250,
            "logo-name": {
              display: "block",
            },
          },
        },
      }}
    >
      <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
        <img
          src={logo}
          alt="logo"
          className="rounded-2"
          style={{
            width: "40px",
            height: "40px",
            marginRight: hover ? "10px" : "0px",
            transition: "margin-right 0.3s",
          }}
        />
        {hover && (
          <span
            style={{
              color: "white",
              fontSize: "20px",
              transition: "opacity 0.3s",
              fontFamily: theme.typography.fontFamily,
            }}
          >
            Hive Medical
          </span>
        )}
      </div>

      <List
        sx={{
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <ListItem
          button
          component={Link}
          to="/"
          sx={
            isActive("/")
              ? {
                  backgroundColor: "#fedf89",
                  borderRadius: "10px",
                  padding: "8px",
                }
              : { color: "#fedf89", padding: "8px" }
          }
        >
          <ListItemIcon>
            <HomeOutlinedIcon
              sx={isActive("/") ? { color: "#e9660f" } : { color: "#fedf89" }}
            />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/newcall"
          sx={
            isActive("/newcall")
              ? {
                  backgroundColor: "#fedf89",
                  borderRadius: "10px",
                  padding: "8px",
                }
              : { color: "#fedf89", padding: "8px" }
          }
        >
          <ListItemIcon>
            <AccountBoxOutlined
              sx={
                isActive("/newcall")
                  ? { color: "#e9660f" }
                  : { color: "#fedf89" }
              }
            />
          </ListItemIcon>
          <ListItemText primary="Patients" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/patients"
          sx={
            isActive("/patients")
              ? {
                  backgroundColor: "#fedf89",
                  borderRadius: "10px",
                  padding: "8px",
                  height: "40px",
                }
              : { color: "#fedf89", padding: "8px" }
          }
        >
          <ListItemIcon>
            <FormatListBulleted
              sx={
                isActive("/patients")
                  ? { color: "#e9660f" }
                  : { color: "#fedf89" }
              }
            />
          </ListItemIcon>
          <ListItemText primary="Patients List " />
        </ListItem>
      </List>
      <List
        sx={{
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "auto",
        }}
      >
        <ListItem
          button
          component={Link}
          // to="/addProfile"
          sx={
            isActive("/addProfile")
              ? {
                  backgroundColor: "#fedf89",
                  borderRadius: "10px",
                  padding: "8px",
                }
              : { color: "#fedf89", padding: "8px" }
          }
        >
          <ListItemIcon>
            <SettingsOutlined
              sx={
                isActive("/addProfile")
                  ? { color: "#e9660f" }
                  : { color: "#fedf89" }
              }
            />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/profile"
          sx={
            isActive("/profile")
              ? {
                  backgroundColor: "#fedf89",
                  borderRadius: "10px",
                  padding: "8px",
                }
              : { color: "#fedf89", padding: "8px" }
          }
        >
          <ListItemIcon
            sx={{
              minWidth: "40px",
            }}
          >
            <Avatar
              sx={
                isActive("/profile")
                  ? { color: "#e9660f", width: 30, height: 30 }
                  : { color: "#fedf89", width: 30, height: 30 }
              }
              alt="Recepient Profile Pic"
              src={Dp}
            />
          </ListItemIcon>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <span className="fs-6">{user?.name}</span>
              <span className="fs-6">{user?.email}</span>
            </div>
            <div className="" onClick={() => handleLogout()}>
              <Logout />
            </div>
          </div>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
