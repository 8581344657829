import { Box } from "@mui/material";
import React, { useEffect } from "react";
import ReactQuill from "react-quill";

const toolbar = {
  toolbar: [
    [{ font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const TextFieldGmail = ({ onData, showJitsi }) => {
  const [editorText, setEditorText] = React.useState("");

  useEffect(() => {
    if (showJitsi) {
      setEditorText("");
    }
  }, [showJitsi]);
  
  const handleDataFromChild = (data) => {
    setEditorText(data);
    onData(data);
  };
  return (
    <div
      style={{
        width: "100%",
      }}
      className="mx-auto"
    >
      <Box>
        <ReactQuill
          modules={toolbar}
          theme="snow"
          value={editorText}
          onChange={handleDataFromChild}
        />
      </Box>
    </div>
  );
};

export default TextFieldGmail;
