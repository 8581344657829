import {
  CancelOutlined,
  Close,
  CloudUpload,
  Delete,
  RemoveRedEye,
  ViewAgenda,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PreviewModal from "../views/chat/Components/PreviewModal";
import theme, { getCustomColor } from "../layout/theme";

const dropZoneStyle = {
  width: "100%",
  minHeight: 200,
  backgroundColor: "#f0f0f0",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  border: "2px dashed #aaa",
  borderRadius: "4px",
  transition: "border .3s ease-in-out",
};

const style = {
  root: {
    flexGrow: 1,
    padding: "20px",
  },
  fileItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    border: "solid 1px #EAECF0",
    borderRadius: "10px",
    padding: "5px ",
  },
  fileIcon: {
    marginRight: "10px",
  },
};

const compressFile = async (file) => {
  // Simulated compression function
  // console.log(`Compressing ${file.name}`);
  return file;
};
const DragAndDrop = ({ onDataFiles , sentfiles}) => {
   
  const [files, setFiles] = useState([]);
  // console.log(files)
  useEffect(() => {
    if(sentfiles){
      setFiles(sentfiles)
    }
  }, [sentfiles])

  const handleDrop = async (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files);
    const compressedFiles = await Promise.all(
      newFiles.map(async (file) => {
        if (file.size > 500 * 1024 * 1024) {
          return await compressFile(file);
        }
        return file;
      })
    );
    setFiles([...files, ...compressedFiles]);
  };

  const handleFileChange = async (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.target.files);
    const compressedFiles = await Promise.all(
      newFiles.map(async (file) => {
        if (file.size > 500 * 1024 * 1024) {
          return await compressFile(file);
        }
        return file;
      })
    );
    setFiles([...files, ...compressedFiles]);
    onDataFiles([...files, ...compressedFiles]);
  };

  const handleRemoveFile = (indexToRemove) => {
    const updatedFiles = files.filter((file, index) => index !== indexToRemove);
    setFiles(updatedFiles);
  };

  //preview modal

  const [showModal, setShowModal] = useState(false);
  const [previewContent, setPreviewContent] = useState({});

  const openModal = (type, url) => {
    setPreviewContent({ type, url });
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  function shortenFileName(fileName) {
    // Check if the file name is longer than 15 characters
    if (fileName.length > 15) {
        // Find the extension (if any)
        const extIndex = fileName.lastIndexOf('.');
        const hasExtension = extIndex !== -1;
        const extension = hasExtension ? fileName.slice(extIndex) : '';

        // Shorten the base name to fit within the limit
        const baseName = fileName.slice(0, hasExtension ? extIndex : undefined);
        const shortenedBaseName = baseName.slice(0, 12); // 12 characters for base name

        // Construct the shortened file name
        const shortenedFileName = shortenedBaseName + '...' + extension;

        return shortenedFileName;
    } else {
        // Return the original file name if it's within the limit
        return fileName;
    }
}
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <label htmlFor="fileInput" className="w-100">
            <Paper
              style={dropZoneStyle}
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
            >
              <CloudUpload />
              <Typography sx={{  fontFamily: theme.typography.fontFamily,}}>
                {" "}
                <span style={{ color: "orange" }}>Click to upload</span> Drag &
                Drop files here
              </Typography>
              <Typography variant="body1" sx={{  fontFamily: theme.typography.fontFamily,}}>
                (Images, videos, and files)
              </Typography>
            </Paper>
            <input
              type="file"
              accept="image/*, video/mp4 , docx, pdf, text"
              multiple
              style={{ display: "none" }}
              onChange={handleFileChange}
              id="fileInput"
            />
          </label>
        </Grid>
        <Grid item xs={12}>
          {/* <Paper style={{ padding: "20px" }}> */}
          {files.map((file, index) => (
            <div key={index} style={style.fileItem}>
              <Box display="flex">
                <Typography style={style.fileIcon}>
                  {file.type.includes("image") ? (
                    <img src={URL.createObjectURL(file)} width={30} />
                  ) : file.type.includes("video") ? (
                    <video src={URL.createObjectURL(file)} width={30} />
                  ) : file.type.includes("pdf") ? (
                    "📄"
                  ) : file.type.includes("doc") ? (
                    "📝"
                  ) : (
                    "📂"
                  )}
                </Typography>
                <Typography sx={{overflow: 'hidden'}}>{shortenFileName(file.name)}</Typography>
              </Box>
              <Box display="flex">
                <IconButton
                  onClick={() =>
                    openModal(
                      file.type.includes("image")
                        ? "image"
                        : file.type.includes("video")
                        ? "video"
                        : file.type.includes("pdf")
                        ? "file"
                        : file.type.includes("doc")
                        ? "file"
                        : "file",
                      URL.createObjectURL(file)
                    )
                  }
                >
                  <RemoveRedEye />
                </IconButton>
                <IconButton onClick={() => handleRemoveFile(index)}>
                  <CancelOutlined  sx={{
                    color: getCustomColor('customColor1')
                  }}/>
                </IconButton>
              </Box>
            </div>
          ))}
          {/* </Paper> */}
        </Grid>
      </Grid>

      <PreviewModal
        show={showModal}
        content={previewContent}
        onClose={closeModal}
      />
    </div>
  );
};

export default DragAndDrop;
