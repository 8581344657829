import React, { useState, useEffect } from "react";
import { auth } from "../../FirebaseAuth";
import { onAuthStateChanged } from "firebase/auth";

const CheckEmailVerification = ({ onVerified }) => {
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Check email verification status
        if (user.emailVerified) { 
          onVerified(); // Call the callback to handle verified email
        } else {
          // Poll every 10 seconds to check email verification status
          const interval = setInterval(async () => {
            await user.reload();
            if (user.emailVerified) {
              clearInterval(interval);  
              onVerified(); // Call the callback to handle verified email
            }
          }, 10000);

          // Clean up interval on component unmount
          return () => clearInterval(interval);
        }
      } else {
        // Handle the case where the user is not logged in
        console.log("No user logged in.");
      }
      setLoading(false);
    });

    // Clean up Firebase listener on component unmount
    return () => unsubscribe();
  }, [onVerified]);

  if (loading) {
    return null
  }

  return null;
};

export default CheckEmailVerification;
