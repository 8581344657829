import React from 'react';
import { Box, Rating } from '@mui/material';

const RatingComponent = ({ value, readOnly, onChange }) => {
  return (
    <Box>
      <Rating
        value={value}
        readOnly={readOnly}
        onChange={(event, newValue) => {
          if (!readOnly) {
            onChange(newValue);
          }
        }}
      />
    </Box>
  );
};

export default RatingComponent;