import React from "react";
import "./PreviewModal.css";
import { Close } from "@mui/icons-material";

const PreviewModal = ({ show, content, onClose }) => {
  if (!show) {
    return null;
  }

  let previewContent;
  if (content.type === "image") {
    previewContent = (
      <img
        src={content.url}
        alt="Preview"
        className="object-fit-contain"
        style={{
          height: "80vh", 
          width: "500px"
        }}
      />
    );
  } else if (content.type === "video") {
    previewContent = (
      <video
        controls
        src={content.url}
        className="object-fit-contain"
        style={{
          height: "80vh",
          width: "500px"
        }}
      />
    );
  } else {
    previewContent = <iframe src={content.url} title="Preview" />;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="text-end px-2 pt-2">
          <Close onClick={onClose} sx={{
            "&:hover": {
              cursor: "pointer"
            }
          }} />
        </div>
        <div className="p-2">{previewContent}</div>
      </div>
    </div>
  );
};

export default PreviewModal;
