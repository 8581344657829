import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { Lock } from "@mui/icons-material";
import theme from "../layout/theme";
const Query = ({ text }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const truncatedContent = () => {
    const words = text.split(" ");
    if (words.length > 20) {
      return words.slice(0, 20).join(" ") + "...";
    }
    return text;
  };
  return (
    <div>
      <Box
        sx={{
          border: "1px solid #ccc",
          borderRadius: "10px",
        }}
      >
        <Box
          textAlign="start"
          sx={{
            backgroundColor: "#f0efef",
            borderBottom: "1px solid #e5e4e4",
            borderRadius: "10px 10px 0px 0px ",
            padding: "5px",
            color: "#989696",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Lock fontSize="15px" mr="5px" /> Clinical Query
        </Box>

        <Box
          sx={{
            height: expanded ? "auto" : "auto",
            overflow: "hidden",
            position: "relative",
            border: "1px solid #ccc",
            margin: "10px",
            borderRadius: "5px",
            padding: "5px",
          }}
        >
          <Typography
            variant="body1"
            style={{
              whiteSpace: "pre-wrap",
              textAlign: "start",
              fontSize: "15px",
              fontFamily: theme.typography.fontFamily,
            }}
          >
            {expanded ? text : truncatedContent()}
          </Typography>
          {!expanded && (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                background: "white",
                cursor: "pointer",
              }}
              onClick={toggleExpanded}
            >
              <Typography variant="body2" color="primary" sx={{  fontFamily: theme.typography.fontFamily,}}>
                Read more
              </Typography>
            </div>
          )}
          {expanded && (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                background: "white",
                cursor: "pointer",
              }}
              onClick={toggleExpanded}
            >
              <Typography variant="body2" color="primary" sx={{  fontFamily: theme.typography.fontFamily,}}>
                Read less
              </Typography>
            </div>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Query;
