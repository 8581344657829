import { VideoCallRounded } from "@mui/icons-material";
import React from "react";
import Jitsi from "./Jitsi";

let popupWindow = null;
const JitsiMeetPopup = ({ roomName, name }) => {
  const openPopup = () => {
    const domain = "meet.jit.si";
    const config = {
      userInfo: {
        displayName: name,
      },
      configOverwrite: {
        disableBranding: true,
        showBranding: false,
        disableSelfView: true,
        disableWelcomePage: true,
      },
      interfaceConfigOverwrite: {
        HIDE_DEEP_LINKING_LOGO: true,
        FILMSTRIP_ENABLED: false,
      },
    };
    const query = Object.entries(config)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            JSON.stringify(value)
          )}`
      )
      .join("&");

    const url = `https://${domain}/${roomName}?config.userInfo.displayName=${name}&config.disableWelcomePage=true&config.disableBranding=true`;
    // const popup = window.open(url, "_blank", "width=800,height=600");
    const popup = window.open(`/videocall?roomName=${roomName}&name=${name}`, "_blank", "width=800,height=600");
    if (popup) {
      popup.focus();
    } else {
      alert("Please allow popups for this website");
    }
  };

  // const openPopup = () => {
  //   const popup = window.open('', '_blank', 'width=800,height=600');

  //   if (popup) {
  //     // Create a container for the Jitsi component
  //     const container = popup.document.createElement('div');
  //     container.id = 'jitsi-container';
  //     container.style.height = '100vh';
  //     container.style.width = '100vw';
  //     popup.document.body.appendChild(container);

  //     // Add a script to load the JitsiMeetExternalAPI
  //     const script = popup.document.createElement('script');
  //     script.src = 'https://meet.jit.si/external_api.js';
  //     script.onload = () => {
  //       // Render the Jitsi Meet component in the new window
  //       const api = new window.JitsiMeetExternalAPI('meet.jit.si', {
  //         roomName: roomName,
  //         width: '100%',
  //         height: '100%',
  //         parentNode: container,
  //         userInfo: {
  //           displayName: name,
  //         },
  //         configOverwrite: {
  //           showBranding: false,
  //           disableSelfView: true,
  //           disableWelcomePage: true,
  //         },
  //         interfaceConfigOverwrite: {
  //           FILMSTRIP_ENABLED: false,
  //         },
  //       });
  //     };
  //     popup.document.head.appendChild(script);

  //     popup.focus();
  //   } else {
  //     alert('Please allow popups for this website');
  //   }
  // };

  return (
    <div>
      {/* <button >Open Jitsi Meet</button> */}
      <VideoCallRounded sx={{ color: "white" }} onClick={openPopup} />
    </div>
  );
};

export default JitsiMeetPopup;
