import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import CommonBotton from "../../components/CommonBotton";
import {
  CancelOutlined,
  Close,
  CloudUpload,
  EditOutlined,
  EmailOutlined,
  PrintOutlined,
  Send,
  SendOutlined,
} from "@mui/icons-material";
import Query from "../../components/Query";
import TextFieldGmail from "../../components/TextFieldGmail";
import DragAndDrop from "../../components/DragAndDrop";
import Document from "../../components/Document";
import CallTable from "../../components/CallTable";
import LayoutSidebar from "../../layout/Layout";
import BlankCommonBotton from "../../components/BlankCommonButton";
import { GrDocumentDownload } from "react-icons/gr";
import pdfImg from "../../assets/pdf.png";
import zipImg from "../../assets/zip.png";
import docImg from "../../assets/doc.png";
import theme, { getCustomColor } from "../../layout/theme";

const style = {
  root: {
    flexGrow: 1,
    padding: "20px",
  },
  fileItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    border: "solid 1px #EAECF0",
    borderRadius: "10px",
    padding: "5px ",
  },
  fileIcon: {
    marginRight: "10px",
  },
};

const CallLogScreen = ({ onScreenChange }) => {
  const handleDataFromChild = (data) => {
    // console.log(data);
  };

  //Document
  const [files, setFiles] = useState([
    {
      lastModified: 1712669111945,
      name: "Document(1).docx",
      size: 0,
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    },
    {
      lastModified: 1712669111945,
      name: "Document(1).pdf",
      size: 0,
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.pdf",
    },
  ]);

  const handleRemoveFile = (indexToRemove) => {
    const updatedFiles = files.filter((file, index) => index !== indexToRemove);
    setFiles(updatedFiles);
  };
  return (
    // <LayoutSidebar>
    <>
      <Grid container spacing={1} mt={2}>
        <Grid item md={8.5} xs={12}>
          <Stack direction="row">
            <Grid container spacing={1}>
              <Grid
                item
                sm={10}
                xs={10}
                p={2}
                sx={{
                  display: "flex",
                }}
              >
                <Box pl={2}>
                  <Typography
                    variant="h5"
                    textAlign="left"
                    sx={{ fontFamily: theme.typography.fontFamily }}
                  >
                    {"< Call log >"}
                  </Typography>
                  <Typography
                    textAlign="left"
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      textAlign: "left !important",
                    }}
                  >
                    {
                      "<Insert Instruction> A record of your call will be saved for future reference. Call participants will also receive a copy of it."
                    }
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                sm={2}
                xs={2}
                textAlign="end"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CommonBotton
                  text="Save"
                  icon={<SendOutlined />}
                  onClick={() => onScreenChange(1)}
                />
              </Grid>
            </Grid>
          </Stack>
          <Box p={2}>
            <Query
              text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt hic debitis asperiores distinctio obcaecati dolorem unde cum accusantium expedita magni incidunt excepturi, rerum autem necessitatibus ab laboriosam tenetur provident voluptatibus?
Voluptatibus, possimus. Animi nostrum esse tenetur blanditiis debitis facilis sapiente, in qui sit non doloribus officiis magnam iure nihil necessitatibus sequi explicabo harum voluptates, nam modi voluptatem dignissimos iusto. Culpa."
            />

            <Box
              my={2}
              sx={{
                border: "solid 1px #ccc",
                borderRadius: "10px",
              }}
            >
              <Box
                textAlign="start"
                sx={{
                  backgroundColor: "#f0efef",
                  borderBottom: "1px solid #e5e4e4",
                  borderRadius: "10px 10px 0px 0px ",
                  padding: "5px",
                  color: "#989696",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Consult summary
              </Box>
              <Box m={3}>
                <TextFieldGmail height="150px" onData={handleDataFromChild} />
              </Box>
            </Box>

            <Box
              my={2}
              sx={{
                border: "solid 1px #ccc",
                borderRadius: "10px",
              }}
            >
              <Box
                textAlign="start"
                sx={{
                  backgroundColor: "#f0efef",
                  borderBottom: "1px solid #e5e4e4",
                  borderRadius: "10px 10px 0px 0px ",
                  padding: "5px 8px",
                  color: "#989696",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Document
                <CloudUpload />
              </Box>
              <Box m={3} mx={1}>
                <div className="align-items-center justify-content-between gap-3">
                  {files.map((file, index) => (
                    <div key={index} style={style.fileItem} className="col">
                      <Box display="flex">
                        <Typography style={style.fileIcon}>
                          {
                            file.type.includes("image") ? (
                              <img src={zipImg} width={30} />
                            ) : // "🖼️"
                            file.type.includes("video") ? (
                              <img src={zipImg} width={30} />
                            ) : // "📹"
                            file.type.includes("pdf") ? (
                              <img src={pdfImg} width={30} />
                            ) : // "📄"
                            file.type.includes("doc") ? (
                              <img src={docImg} width={30} />
                            ) : (
                              // "📝"
                              <img src={zipImg} width={30} />
                            )
                            // "📂"
                          }
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: theme.typography.fontFamily,
                            fontSize: "13px",
                            fontWeight: "500",
                            lineHeight: "20px",
                            textAlign: "left",
                            color: "#344054",
                            padding: "0 20px 0 0",
                          }}
                        >
                          {file.name}
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton onClick={() => handleRemoveFile(index)}>
                          <CancelOutlined
                            sx={{
                              color: getCustomColor("customColor1"),
                            }}
                          />
                        </IconButton>
                      </Box>
                    </div>
                  ))}
                </div>
              </Box>
            </Box>

            <CallTable />
          </Box>
        </Grid>
        <Divider
          flexItem
          orientation="vertical"
          sx={{ mx: 2, my: 1, display: { sm: "none", md: "block" } }}
        />
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            background: "#fffaeb",
            border: `1px solid #FEDF89`,
            borderRadius: "15px",
            padding: "15px !important",
            width: "100%",
            height: "100%",
          }}
        >
          <Box>
            <Stack textAlign="start">
              <Typography variant="h6" fontWeight={501}>
                Actions
              </Typography>
              <Typography
                fontSize="15px"
                sx={{
                  fontFamily: theme.typography.fontFamily,
                }}
              >
                Following actions that you can take
              </Typography>
            </Stack>
          </Box>
          <Divider
            sx={{
              marginTop: "15px",
              marginBottom: "15px",
            }}
          />
          <Box textAlign="start">
            <p className="mb-0">Referal</p>
            <p>
              Generate a referral letter from a pre-filled template for patient
            </p>
          </Box>
          <Box
            textAlign="start"
            mb={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              gap: 1,
            }}
          >
            <CommonBotton text="Create Referral" icon={<EditOutlined />} />
            <Button
              type="submit"
              variant="outlined"
              sx={{
                backgroundColor: "red",
                borderColor: "red",
                color: "white",
                textTransform: "capitalize",
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: "#ce1818",
                  borderColor: "red",
                  color: "white",
                },
              }}
              startIcon={<CancelOutlined />}
            >
              Cancel Referral
            </Button>
          </Box>
          <Box textAlign="start">
            <p className="mb-0">Summary</p>
            <p>Generate a copy of the consultation for your patient</p>
            <Box>
              <BlankCommonBotton
                text="Download PDF"
                icon={<GrDocumentDownload />}
              />
            </Box>
            <Box>
              <BlankCommonBotton text="Email" icon={<EmailOutlined />} />
            </Box>
            <Box>
              <BlankCommonBotton text="Print" icon={<PrintOutlined />} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
    // </LayoutSidebar>
  );
};

export default CallLogScreen;
