import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Lock, PriorityHigh, SendRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import CommonBotton from "./CommonBotton";
import BlankCommonBotton from "./BlankCommonButton";
import { useState } from "react";
import pdfImg from "../assets/pdf.png";
import zipImg from "../assets/zip.png";
import docImg from "../assets/doc.png";
import theme from "../layout/theme";

const style = {
  root: {
    flexGrow: 1,
    padding: "20px",
  },
  fileItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    border: "solid 1px #EAECF0",
    borderRadius: "10px",
    padding: "5px ",
  },
  fileIcon: {
    marginRight: "10px",
  },
};

export default function Accordin({ title, expand }) {
  // const [expanded, setExpanded] = useState('panel1');
  const [dispute, setDispute] = useState(false);
  const toggleDispute = () => {
    setDispute(!dispute);
  };

  const [files] = useState([
    {
      lastModified: 1712669111945,
      name: "Document(1).docx",
      size: 0,
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    },
    {
      lastModified: 1712669111945,
      name: "Document(1).pdf",
      size: 0,
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.pdf",
    },
    {
      lastModified: 1712669111945,
      name: "img.jpeg",
      size: 0,
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.image",
    },
  ]);

  return (
    <div className=" ">
      <Accordion
        sx={{
          // backgroundColor: "#eaeaea",
          border: "1px solid gray",
          borderRadius: "10px !important",
          // margin: "10px 0",
          width: "100%",
          overflow: "hidden",
        }}
        // expanded={expand}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            backgroundColor: "#F9FAFB",
            height: "20px",
            minHeight: "48px !important",
            // borderRadius: "10px !important",
            borderTopLeftRadius: "10px !important",
            borderTopRightRadius: "10px !important",
            // margin: "10px 0",
          }}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              color: "#475467",
              fontSize: "12px",
              fontWeight: "500",
              lineHeight: "18px",
              textAlign: "left",
            }}
          >
            <Lock fontSize="15px" mx="5px" /> {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              // border: "solid 1px gray",
              borderRadius: "8px",
              // backgroundColor: "#f5efef",
              textAlign: "start",
              padding: "5px",
            }}
          >
            {title !== "Documents" ? (
              `Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
            veniam sit quisquam repellat sequi qui magni adipisci quasi! Culpa
            dignissimos autem quae corrupti dolore nihil eius porro! Tempore
            nobis autem veritatis voluptas soluta incidunt accusamus, labore
            impedit nemo architecto beatae similique tenetur officiis sequi
            blanditiis magnam asperiores error velit provident!`
            ) : (
              <div className="d-md-flex align-items-center justify-content-between gap-3">
                {files.map((file, index) => (
                  <div key={index} style={style.fileItem} className="col">
                    <Box display="flex">
                      <Typography style={style.fileIcon}>
                        {
                          file.type.includes("image") ? (
                            <img src={zipImg} width={30} />
                          ) : // "🖼️"
                          file.type.includes("video") ? (
                            <img src={zipImg} width={30} />
                          ) : // "📹"
                          file.type.includes("pdf") ? (
                            <img src={pdfImg} width={30} />
                          ) : // "📄"
                          file.type.includes("doc") ? (
                            <img src={docImg} width={30} />
                          ) : (
                            // "📝"
                            <img src={zipImg} width={30} />
                          )
                          // "📂"
                        }
                      </Typography>
                      <Typography
                        sx={{ 
                          fontFamily: theme.typography.fontFamily,
                          fontSize: "13px",
                          fontWeight: "500",
                          lineHeight: "20px",
                          textAlign: "left",
                          color: "#344054",
                          padding: "0 20px 0 0",
                        }}
                      >
                        {file.name}
                      </Typography>
                    </Box>
                  </div>
                ))}
              </div>
            )}
          </Box>
          {title === "Case Summary" ? (
            dispute !== true ? (
              <Box
                m={2}
                sx={{
                  p: 2,
                  border: "solid 1px orange",
                  backgroundColor: "#fffaeb",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  p={1}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PriorityHigh
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                      fontSize: "30px",
                      color: "#ff7700",
                      backgroundColor: "#edcf97",
                      border: "solid 3px orange",
                      borderRadius: "100%",
                      boxShadow:
                        "0px 0px 0px 7.5px #edcf97, #e5cfa5af 0px 0px 0px 15px",
                      margin: "10px",
                    }}
                  />
                  <Box textAlign="start" ml={2}>
                    <Typography
                      sx={{
                        color: "#ff7700",
                        fontSize: "12px",
                        fontFamily: theme.typography.fontFamily,
                      }}
                    >
                      Quick head-up! You have a limit of 3 dispute attempts
                      available{" "}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#ff7700",
                        fontSize: "10px",
                        fontFamily: theme.typography.fontFamily,
                      }}
                    >
                      You have 1 dispute attempt available{" "}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <CommonBotton
                    text="File a Dispute"
                    onClick={() => toggleDispute()}
                  />
                </Box>
              </Box>
            ) : (
              <Box m={2} textAlign="start">
                <label htmlFor="dispute" className="mb-2">
                  Reason for dispute
                </label>
                <textarea
                  className="disputeField"
                  name=""
                  id="dispute"
                  rows="4"
                  placeholder="Type here..."
                  style={{
                    border: "solid 4px #FEDF89",
                    resize: "none",
                    borderRadius: "10px",
                    width: "100%",
                  }}
                ></textarea>

                <Box textAlign="end">
                  <BlankCommonBotton
                    text="Cancel"
                    onClick={() => toggleDispute()}
                  />{" "}
                  &nbsp;
                  <CommonBotton text="Send" icon={<SendRounded />} />
                </Box>
              </Box>
            )
          ) : null}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
