import { Box, IconButton, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GrDocumentImage } from "react-icons/gr";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import theme from "../../../../layout/theme";

function LinearProgressWithLabel({ value }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={value} color="warning" />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const SendingFile = ({ show, content, progress }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (content) {
      setFiles((prevFiles) => [...prevFiles, ...content]);
    }
  }, [content]);
  
  if (!show || !files.length) {
    return null;
  }


  
  const filesArray = Array.from(content);
  // const filesArray = Array.from(content).filter((_, index) => progress[index] < 100);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 , justifyContent: 'space-between', width : '100%' , px: 1}}>
        <Typography sx={{  fontFamily: theme.typography.fontFamily,}}>Sending...</Typography>
        <IconButton onClick={toggleDrawer} sx={{ ml: 1 }}>
          {drawerOpen ? <MdKeyboardArrowDown /> : <MdKeyboardArrowUp />}
        </IconButton>
      </Box>
      {drawerOpen &&
        filesArray.map((file, index) => {
          const url = URL.createObjectURL(file);
          const type = file.type.split("/").shift();

          return (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1 , width : '100%' , px: 1}}>
              {type === "image" ? (
                <img src={url} alt="" className="object-fit-contain m-1" width={40} />
              ) : type === "video" ? (
                <video src={url} className="object-fit-contain m-1" width={40} />
              ) : (
                <GrDocumentImage />
              )}
              <Box ml={1} sx={{ width : '100%' }}>
              <Typography variant="body1">Sending File: {file.name}</Typography>
                <LinearProgressWithLabel value={progress[index]} />
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default SendingFile;
