import React, { useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import Accordin from "../../components/Accordin";
import SideCallDetail from "../../components/SideCallDetail";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import pdfImg from "../../assets/pdf.png";
import zipImg from "../../assets/zip.png";
import docImg from "../../assets/doc.png";
import theme from "../../layout/theme";

const style = {
  root: {
    flexGrow: 1,
    padding: "20px",
  },
  fileItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    border: "solid 1px #EAECF0",
    borderRadius: "10px",
    padding: "5px ",
  },
  fileIcon: {
    marginRight: "10px",
  },
};

const CallLogP2 = () => {
  const [files] = useState([
    {
      lastModified: 1712669111945,
      name: "Document(1).docx",
      size: 0,
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    },
    {
      lastModified: 1712669111945,
      name: "Document(1).pdf",
      size: 0,
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.pdf",
    },
  ]);

  return (
    // <LayoutSidebar>
    <div className="p-3">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={8.5}>
          <Box mb={2}>
            <Stack textAlign="start">
              <Typography variant="h6" sx={{  fontFamily: theme.typography.fontFamily,}}>
                Consultation with Dr Benjamin
              </Typography>
              <Typography sx={{  fontFamily: theme.typography.fontFamily,}}>Call logged on 30 Dec 2023</Typography>
            </Stack>
          </Box>
          <Box>
            <Accordin title="Clinical Query" />
          </Box>
          <Box my={2}>
            <Accordin title="Case Summary" />
          </Box>
        </Grid>
        <Divider flexItem orientation="vertical" sx={{ mx: 2, my: 1 }} />
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          // sx={{
          //   background: "#fffaeb",
          //   borderRadius: "15px",
          //   padding: "15px !important",
          //   width: "100%",
          // }}
        >
          <Box mb={2}>
            <Stack textAlign="start">
              <Typography variant="h6" sx={{  fontFamily: theme.typography.fontFamily,}}>Supporting Documents</Typography>
              <Typography sx={{  fontFamily: theme.typography.fontFamily,}}>Dr X-X uploaded</Typography>
            </Stack>
          </Box>
          <Box>
            <Accordion
              sx={{
                // backgroundColor: "#eaeaea",
                border: "1px solid gray",
                borderRadius: "10px !important",
                // margin: "10px 0",
                width: "100%",
                overflow: "hidden",
              }}
              // expanded={expand}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                  backgroundColor: "#F9FAFB",
                  height: "20px",
                  minHeight: "48px !important",
                  // borderRadius: "10px !important",
                  borderTopLeftRadius: "10px !important",
                  borderTopRightRadius: "10px !important",
                  // margin: "10px 0",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: theme.typography.fontFamily,
                    display: "flex",
                    alignItems: "center",
                    color: "#475467",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "18px",
                    textAlign: "left",
                  }}
                >
                  Document
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  sx={{
                    // border: "solid 1px gray",
                    borderRadius: "8px",
                    // backgroundColor: "#f5efef",
                    textAlign: "start",
                    padding: "5px",
                  }}
                >
                  <div className="align-items-center justify-content-between gap-3">
                    {files.map((file, index) => (
                      <div key={index} style={style.fileItem} className="col">
                        <Box display="flex">
                          <Typography style={style.fileIcon}>
                            {
                              file.type.includes("image") ? (
                                <img src={zipImg} width={30} alt="file"/>
                              ) : // "🖼️"
                              file.type.includes("video") ? (
                                <img src={zipImg} width={30} alt="file"/>
                              ) : // "📹"
                              file.type.includes("pdf") ? (
                                <img src={pdfImg} width={30} alt="file"/>
                              ) : // "📄"
                              file.type.includes("doc") ? (
                                <img src={docImg} width={30} alt="file"/>
                              ) : (
                                // "📝"
                                <img src={zipImg} width={30} alt="file"/>
                              )
                              // "📂"
                            }
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: theme.typography.fontFamily,
                              fontSize: "13px",
                              fontWeight: "500",
                              lineHeight: "20px",
                              textAlign: "left",
                              color: "#344054",
                              padding: "0 20px 0 0",
                            }}
                          >
                            {file.name}
                          </Typography>
                        </Box>
                      </div>
                    ))}
                  </div>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box>
            <SideCallDetail />
          </Box>
        </Grid>
      </Grid>
    </div>
    // </LayoutSidebar>
  );
};

export default CallLogP2;
