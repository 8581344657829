import { CheckCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import styled, { useTheme } from "styled-components";
import CommonBotton from "../../components/CommonBotton";
import BlankCommonBotton from "../../components/BlankCommonButton";
import KeyIcon from "@mui/icons-material/Key";
import PersonIcon from "@mui/icons-material/Person";
import DocImage from "../../assets/Rectangle.jpeg";
import logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import SignPass_Logo from "../../assets/image 6.png";
import { auth, db, doc, getDoc, setDoc } from "../../FirebaseAuth";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";
import CheckEmailVerification from "./CheckEmailVerification";
import { toast } from "react-toastify";
import theme from "../../layout/theme";

const Item = styled(Paper)(({ theme }) => ({}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  fontFamily: theme.typography.fontFamily,
};
const FirstScreen = () => {
  const [loading, setLoading] = useState(false);
  const [secondForm, setSecondForm] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const LoginNavigate = () => {
    navigate("/login");
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm();

  const [checkVerification, setCheckVerification] = useState(false);

  useEffect(() => {
    // No additional logic needed here for now
  }, [checkVerification]);

  const [dataE, setDataE] = useState();
  const submit = async (data) => {
    // console.log(data);
    try {
      setDataE(data);
      setLoading(true);
      // setTimeout(() => {
      //   setLoading(false);
      //   setSecondForm(true);
      // }, 3000);
      await createUserWithEmailAndPassword(auth, data.email, data.password)
        .then(async (userCredential) => {
          // Signed up
          const user = userCredential.user;
          await user.delete();
          setLoading(false);
          setSecondForm(true);
          // ...
        })
        .catch((error) => {
          console.log(error, "errorerror  ");
          const errorCode = error.code;
          const errorMessage = error.message;
          if (error.message === "auth/email-already-in-use" || "EMAIL_EXISTS") {
            toast.error("Email is already in use.");
          } else if (error.message === "auth/invalid-email") {
            toast.error("Invalid email address.");
          } else if (error.message === "auth/weak-password") {
            console.log("Password is too weak.");
          } else {
            console.log("Error: Unable to create user.");
          }
          setLoading(false);
          // ..
        });
    } catch (error) {
      setLoading(true);
    }

    // handleOpen();
  };

  const submit2 = async (data) => {
    // console.log(data);
    try {
      setLoading(true);
      await createUserWithEmailAndPassword(auth, dataE.email, dataE.password)
        .then(async (userCredential) => {
          // Signed up
          const user = userCredential.user;
          await sendEmailVerification(user);
          toast.success("Verification email sent. Please check your inbox.");
          setCheckVerification(true);

          await setDoc(doc(db, "users", user.uid), {
            email: dataE.email,
            name: `${data.saluation} ${data.name}`,
            clinic: data.clinic_name,
            medical_licence: data.medical_licence,
          });
          // Retrieve and print stored user data
          const docSnapshot = await getDoc(doc(db, "users", user.uid));
          if (docSnapshot.exists()) {
            console.log("Stored User Data:", docSnapshot.data());
          } else {
            console.log("No data found for user.");
          }
          // ...
        })
        .catch((error) => {
          console.log(error, "errorerror  ");
          const errorCode = error.code;
          const errorMessage = error.message;
          if (error.message === "auth/email-already-in-use" || "EMAIL_EXISTS") {
            toast.error("Email is already in use.");
          } else if (error.message === "auth/invalid-email") {
            toast.error("Invalid email address.");
          } else if (error.message === "auth/weak-password") {
            console.log("Password is too weak.");
          } else {
            console.log("Error: Unable to create user.");
          }
          setLoading(false);
          // ..
        });
    } catch (error) {
      console.log(error, "error");
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const handleChange = (pass) => {
    // var pass = event.target.value;
    if (pass.length > 0) {
      // var reg = /^[A-Z]*$/;
      var reg = /^(?=.*?[#?!@$%^&*-]).{8,}$/;
      var test = reg.test(pass);
      if (pass.length > 8) {
        setCheck1(true);
      } else {
        setCheck1(false);
      }
      if (test === true) {
        setCheck2(true);
      } else {
        setCheck2(false);
      }
    }
  };

  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  //otp
  const [otp, setOTP] = useState(["", "", "", ""]); // Assuming OTP length is 4
  const otpInputs = useRef([]);

  const handleChangeOTP = (index, event) => {
    const value = event.target.value;
    if (isNaN(value)) {
      return; // Allow only numeric input
    }
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    // Move to next input field
    if (value !== "" && index < otp.length - 1) {
      otpInputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && otp[index] === "" && index > 0) {
      // Move to previous input field on backspace if current field is empty
      otpInputs.current[index - 1].focus();
    }
  };

  const onSubmitOTP = () => {
    setSecondForm(true);
    handleClose();
    // console.log(otp, "otp");
  };

  const handleVerificationSuccess = () => {
    // toast.success("email verified successfully");
    setCheckVerification(false);
    setLoading(false);
    navigate("/");
    // Optionally, redirect or update UI as needed
  };
  return (
    <div>
      <Box
        sx={{ flexGrow: 1 }}
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container spacing={2}>
          <Grid
            item
            sm={6}
            md={7}
            lg={8}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <Item
              sx={{
                background: `url(${DocImage}) no-repeat -150px center fixed`,
                backgroundSize: "cover",
                height: "100vh",
                width: "100%",
                display: "flex !important",
                justifyContent: "start !important",
                alignItems: "end !important",
                overflow: "hidden",
                borderRadius: "0px",
                position: "relative",

                "::after": {
                  content: "''",
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: "0%",
                  width: "100%",
                  background:
                    "linear-gradient(to top, rgba(0, 0, 0, 0.818), rgba(0, 0, 0, 0))",
                  mixBlendMode: "multiply",
                  borderRadius: "0px", // Adjust border radius to match container
                  boxShadow: "0px 0 0px rgba(0, 0, 0, 0.3)", // Adjust shadow as needed
                  overflow: "hidden",
                },
              }}
            >
              <Stack sx={{ overflow: "hidden", zIndex: 2, width: "85%" }}>
                <Typography
                  variant="p"
                  px={5}
                  fontSize={45}
                  sx={{
                    color: "white",
                    textAlign: "start",
                    fontFamily: theme.typography.fontFamily,
                  }}
                >
                  Empowering healthcare collabroration
                </Typography>

                <Typography
                  variant="p"
                  fontSize={20}
                  pt={1}
                  pb={5}
                  px={5}
                  sx={{
                    color: "white",
                    textAlign: "start",
                    fontFamily: theme.typography.fontFamily,
                  }}
                >
                  Seamlessly connect general practi tioners with speaci alized
                  care through our secure medicalSaaS platform.
                </Typography>
              </Stack>
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            lg={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                width: "75%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  padding: "12px",
                  border: `solid 1px ${theme.palette.primary.main}`,
                  borderRadius: "100%",
                  boxShadow: `${theme.palette.primary.main} 0px 0px 0px 0.1px inset , rgb(255, 255, 255) 0px 0px 0px 31px , ${theme.palette.primary.main} 0px 0px 0px 31px, rgb(255, 255, 255) 0px 0px 0px 61px , ${theme.palette.primary.main} 0px 0px 0px 61px , rgb(255, 255, 255) 0px 0px 0px 91px , ${theme.palette.primary.main} 0px 0px 0px 91px , rgb(255, 255, 255) 0px 0px 0px 121px , ${theme.palette.primary.main} 0px 0px 0px 121px , rgb(255, 255, 255) 0px 0px 0px 150px , ${theme.palette.primary.main} 0px 0px 0px 150px`,
                }}
              >
                <img src={logo} alt="" width={50} />
              </Box>
              <Typography variant="h5" my={3} sx={{  fontFamily: theme.typography.fontFamily,}}>
                Create an account
              </Typography>
              {secondForm === false ? (
                <form
                  className="w-100"
                  action=""
                  id="signupform"
                  onSubmit={handleSubmit(submit)}
                  noValidate
                >
                  <Box>
                    <Stack
                      spacing={2}
                      sx={{
                        width: "100%",
                      }}
                      // width={{
                      //   xs: 100,
                      //   sm: 300,
                      //   md: 350,
                      // }}
                    >
                      <div className="text-start">
                        <InputLabel shrink htmlFor="email">
                          Email
                        </InputLabel>
                        <TextField
                          id="email"
                          size="small"
                          sx={{
                            backgroundColor: "white",
                          }}
                          fullWidth
                          placeholder="Enter your email"
                          {...register("email", {
                            required: "Email is required",
                            pattern: {
                              // value: /^[a-zA-Z0-9._%+-]+@(gmail|yahoo)\.com$/i,
                              value:
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                              message: "Please enter a valid  email address",
                            },
                          })}
                          error={!!errors.email}
                          helperText={errors.email?.message}
                        />
                      </div>

                      <div className="text-start">
                        <InputLabel shrink htmlFor="pass">
                          Password
                        </InputLabel>
                        <TextField
                          id="pass"
                          size="small"
                          fullWidth
                          placeholder="Create a password"
                          type={showPassword ? "text" : "password"}
                          sx={{
                            backgroundColor: "white",
                          }}
                          // onChange={(e) => handleChange(e.target.value)}
                          {...register("password", {
                            required: "Password is required",
                            onChange: (e) => handleChange(e.target.value),
                          })}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handlePasswordVisibility}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={!!errors.password}
                          helperText={errors.password?.message}
                        />
                      </div>

                      <Stack>
                        <Typography fontSize={13} textAlign="start" sx={{ fontFamily: theme.typography.fontFamily,}}>
                          <Checkbox
                            id="check1"
                            color="primary"
                            checked={check1}
                            icon={<CheckCircle />}
                            checkedIcon={<CheckCircle />}
                            disabled={!check1}
                          />{" "}
                          Must be at least 8 characters
                        </Typography>
                        <Typography fontSize={13} textAlign="start" sx={{
                           fontFamily: theme.typography.fontFamily,
                        }}>
                          <Checkbox
                            id="check2"
                            color="primary"
                            checked={check2}
                            icon={<CheckCircle />}
                            checkedIcon={<CheckCircle />}
                            disabled={!check2}
                          />{" "}
                          Must contain one special character
                        </Typography>
                      </Stack>
                      <CommonBotton
                        text="Continue"
                        loading={loading}
                        type="submit"
                        form="signupform"
                        disabled={
                          check1 === true && check2 === true ? false : true
                        }
                      />
                    </Stack>
                  </Box>
                </form>
              ) : (
                <form
                  className="w-100"
                  action=""
                  onSubmit={handleSubmit2(submit2)}
                  style={{ width: "350px" }}
                  id="createForm"
                >
                  <Stack direction="row" gap={4} px={2}>
                    <div className="text-start">
                      <InputLabel shrink htmlFor="saluation">
                        Saluation
                      </InputLabel>
                      <TextField
                        id="saluation"
                        size="small"
                        sx={{
                          backgroundColor: "white",
                        }}
                        fullWidth
                        {...register2("saluation", {
                          required: "Saluation is required",
                        })}
                        error={!!errors2.saluation}
                        helperText={errors2.saluation?.message}
                        FormHelperTextProps={{ style: { margin: 0 } }}
                      />
                    </div>

                    <div className="text-start">
                      <InputLabel shrink htmlFor="name">
                        Name
                      </InputLabel>
                      <TextField
                        id="name"
                        size="small"
                        sx={{
                          backgroundColor: "white",
                        }}
                        fullWidth
                        variant="outlined"
                        {...register2("name", {
                          required: "Name is required",
                        })}
                        error={!!errors2.name}
                        helperText={errors2.name?.message}
                        FormHelperTextProps={{ style: { margin: 0 } }}
                      />
                    </div>
                  </Stack>
                  <Stack gap={2} my={3} px={2}>
                    <div className="text-start">
                      <InputLabel shrink htmlFor="mlno">
                        Medical License no.
                      </InputLabel>
                      <TextField
                        id="mlno"
                        size="small"
                        fullWidth
                        sx={{
                          backgroundColor: "white",
                        }}
                        {...register2("medical_licence", {
                          required: "Medical Licence is required",
                        })}
                        error={!!errors2.medical_licence}
                        helperText={errors2.medical_licence?.message}
                        FormHelperTextProps={{ style: { margin: 0 } }}
                      />
                    </div>
                    <div className="text-start">
                      <InputLabel shrink htmlFor="Cname">
                        Clinic Name
                      </InputLabel>
                      <TextField
                        id="Cname"
                        size="small"
                        sx={{
                          backgroundColor: "white",
                        }}
                        fullWidth
                        {...register2("clinic_name", {
                          required: "Clinic Name is required",
                        })}
                        error={!!errors2.clinic_name}
                        helperText={errors2.clinic_name?.message}
                        FormHelperTextProps={{ style: { margin: 0 } }}
                      />
                    </div>
                  </Stack>
                  <Divider
                    textAlign="center"
                    sx={{
                      border: "1px solid #E6E6E",
                      height: "40px",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        fontFamily: theme.typography.fontFamily,
                      }}
                    >
                      OR
                    </Typography>
                  </Divider>
                  <BlankCommonBotton
                    image={SignPass_Logo}
                    text="Retrieve info from SingPass"
                    width="100%"
                  />
                  <Box>
                    <CommonBotton
                      text="Continue"
                      form="createForm"
                      type="submit"
                      width="100%"
                      loading={loading}
                    />
                  </Box>
                </form>
              )}

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Verify
                  </Typography>
                  <Typography
                    id="modal-modal-title"
                    variant="p"
                    component="h6"
                    my={2}
                  >
                    Your code was send to you via email
                  </Typography>
                  <Box>
                    {otp.map((digit, index) => (
                      <TextField
                        key={index}
                        inputRef={(el) => (otpInputs.current[index] = el)}
                        variant="outlined"
                        margin="dense"
                        value={digit}
                        onChange={(event) => handleChangeOTP(index, event)}
                        onKeyDown={(event) => handleKeyDown(index, event)}
                        autoFocus={index === 0} // Autofocus on the first input field
                        inputProps={{
                          maxLength: 1, // Allow only one character per input
                          style: { textAlign: "center" }, // Center align text
                        }}
                        style={{ width: 50, margin: "5px" }} // Adjust width as needed
                      />
                    ))}
                  </Box>
                  <CommonBotton
                    text="Verify"
                    width="50%"
                    my={1}
                    onClick={() => onSubmitOTP()}
                  />
                  <Typography
                    id="modal-modal-title"
                    variant="p"
                    component="p"
                    fontSize={12}
                    sx={{
                      fontFamily: theme.typography.fontFamily,
                    }}
                  >
                    Didn't receive code?{" "}
                    <a href="" className="text-underline">
                      Request again
                    </a>
                  </Typography>
                </Box>
              </Modal>
              <Typography
                variant="p"
                sx={{
                  fontSize: "12px",
                  margin: "20px auto !important",
                  fontFamily: theme.typography.fontFamily,
                }}
              >
                Already have an account ?{" "}
                <Typography
                  variant="span"
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: "600",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => LoginNavigate()}
                >
                  Log in
                </Typography>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {checkVerification === true && (
          <CheckEmailVerification onVerified={handleVerificationSuccess} />
        )}
      </Box>
    </div>
  );
};

export default FirstScreen;
