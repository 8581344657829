// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import { auth, onAuthStateChanged } from "../FirebaseAuth";

// Create a Context for authentication
const AuthContext = createContext();

// Provide authentication status and methods
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    // Check authentication status (e.g., from localStorage, cookies, etc.)
    const email = localStorage.getItem("email");
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      // console.log("Auth", currentuser);
      setCurrentUser(currentuser);
      setIsAuthenticated(!!currentuser?.email);
    });
    return () => {
      unsubscribe();
    };
    // console.log(!!email)
    setIsAuthenticated(!!email);
  }, []);

  const login = (email) => {
    localStorage.setItem("email", email);
    setIsAuthenticated(true);
  };

  const logout = () => {
    localStorage.removeItem("email");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, currentUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
