import React from "react";
import { Box} from "@mui/material";
const CallTable = () => {
  return (
    <div>
      <Box
        sx={{
          border: "solid 1px #ccc",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        <table className="table  mb-0">
          <thead
            className="table-secondary "
            style={{
              background: "#ccc !important",
            }}
          >
            <tr className="">
              <th scope="col " className="text-secondary fw-0 rounded-top">
                Call logs
              </th>
              <th scope="col " className="text-secondary fw-0">
                Call Duration
              </th>
              <th scope="col text-secondary" className="text-secondary fw-0">
                Encounter
              </th>
              <th scope="col text-secondary" className="text-secondary fw-0">
                Call ended on
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>30 Dec 2023</td>
              <td>13 min 41 sec</td>
              <td>8:28 PM</td>
              <td>8:41 PM</td>
            </tr>
          </tbody>
        </table>
       
      </Box>
    </div>
  );
};

export default CallTable;
